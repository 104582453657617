import React, { useState } from "react";
import { CLIENT_PO_PROPERTY_MAP } from "../../constants/PurchaseStatus.data";
import "./PurchaseStatusDropdown.scss";

interface PurchaseStatusDropdownProps {
  statuses: string[];
  updateStatus: (value: string) => void;
}

const PurchaseStatusDropdown: React.FC<PurchaseStatusDropdownProps> = ({
  statuses,
  updateStatus,
}) => {
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="quote-status-dropdown-wrapper-container"
    >
      <div className="quote-status-dropdown-main-container p-4 flex-column gap-4">
        {statuses.map((status, index) => {
          return (
            <p
              onClick={() => updateStatus(status)}
              key={index}
              style={{
                color: CLIENT_PO_PROPERTY_MAP[status].color,
                background: CLIENT_PO_PROPERTY_MAP[status].backgroundColor,
              }}
              className="product-status-dropdown py-6  flex-row align-items-center justify-content-center xetgo-font-mini bolder"
            >
              {status}
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default PurchaseStatusDropdown;
