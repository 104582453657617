import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import OutsideAlerter from "../../utils/OutsideClickDetector";
import "./SkuQualityCheckTestCard.scss";
import React, { RefObject, useEffect, useRef, useState } from "react";

interface QcDetailSchema {
  id: number;
  name: string;
  description: string;
  test_method: string | null;
}
interface QualityCheckTestProps {
  qcDetail: QcDetailSchema;
  index: number;
}

const SkuQualityCheckTestCard: React.FC<QualityCheckTestProps> = ({
  qcDetail,
  index,
}) => {
  return (
    <div key={index} className="quality-test quality-test-grid xetgo-font-tag">
      <p className="test-index flex-row align-items-center p-12">
        {index + 1 < 10 ? "00" : index + 1 < 100 ? "0" : ""}
        {index + 1}
      </p>
      <div className="qc-test-middle-container flex-column">
        <div className="test-name-container flex-row justify-content-space-between align-items-center gap-8">
          <p className="test-name xetgo-font-tag p-12 bold flex-1">
            {qcDetail.name}
          </p>
        </div>
        <div className="px-12 py-8 test-description">
          {qcDetail.description}
        </div>
      </div>
      <div className="full-width border-box p-12">
        <p
          className={`quality-check-input test-value px-16 py-8 border-box full-width `}
        >
          {qcDetail.test_method}
        </p>
      </div>
    </div>
  );
};

export default SkuQualityCheckTestCard;
