export const CLIENT_PO_PROPERTY_MAP: {
  [key: string]: { backgroundColor: string; color: string };
} = {
  "PO Received": {
    backgroundColor: "#F3E2FF",
    color: "#6610A6",
  },
  "PO Review": {
    color: "#694B00",
    backgroundColor: "#FFF5DE",
  },
  "Delivery Date Estimation": {
    color: "#004D54",
    backgroundColor: "#DEFCFF",
  },
  "Production Planned": {
    color: "#00553F",
    backgroundColor: "#D6F5ED",
  },
  "Under Production": {
    color: "#004700",
    backgroundColor: "#D2FAD2",
  },
  Scheduled: {
    color: "#8C4D00",
    backgroundColor: "#FFEDD7",
  },
  Delivered: {
    color: "#005209",
    backgroundColor: "#E6FDD7",
  },
  "Artwork Generation": {
    color: "#541900",
    backgroundColor: "#FFEAE1",
  },
  "Artwork Internal Check": {
    color: "#4F0041",
    backgroundColor: "#FFE5FB",
  },
  "Artwork Client Approval": {
    color: "#4F0041",
    backgroundColor: "#FFE5FB",
  },
  "Artwork Re-approval": {
    color: "#4F0041",
    backgroundColor: "#FFE5FB",
  },
  "Artwork Revision": {
    color: "#541900",
    backgroundColor: "#FFEAE1",
  },
  "Artwork Approved": {
    color: "#005209",
    backgroundColor: "#E4FFE7",
  },
};
