import React from "react";
import "./PurchaseListCard.scss";
import { CLIENT_PO_PROPERTY_MAP } from "../../constants/PurchaseStatus.data";
import { commaFormat, round } from "../../utils/Helper";
import { PurchaseOrderCard } from "../../models/PurchaseOrder";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

interface PurchaseListCardProps {
  details: PurchaseOrderCard;
  index: number;
  key: number;
}
const PurchaseListCard: React.FC<PurchaseListCardProps> = ({
  key,
  details,
  index,
}) => {
  const navigate = useNavigate();
  const dateToString = (entry: string) => {
    if (entry == null) {
      return "";
    }
    return dayjs(entry).format("DD MMM YYYY");
  };

  return (
    <div
      key={key}
      className="purchase-card-container xetgo-font-tag cursor-pointer"
      onClick={() => {
        navigate(`/purchase-orders/${details.id}`);
      }}
    >
      <p className="index table-border py-12 text-align-center tab-below-hidden-field">
        {index}
      </p>
      <div className="enquiry-id-pill-container flex-row gap-8 align-items-center p-8 table-border">
        <p className="enquiry-id-pill px-12 py-4">{details.purchase_id}</p>
      </div>
      <p className="p-12 enquiry-on-container table-border text-align-center tab-below-hidden-field">
        {dateToString(details.ordered_on)}
      </p>
      <p className="p-12 enquiry-by-container table-border tab-below-hidden-field">
        {details.poc_name},{" "}
        <span className="sub-title">{details.company_name}</span>
      </p>
      <p className="p-12 table-border po-value-box flex-row align-items-center">
        {" "}
        {details.po_value > 0
          ? `₹ ${commaFormat(round(details.po_value, 2).toString())}`
          : "--"}
      </p>
      <div className="flex-row p-12 justify-content-space-between table-border product-count-box">
        <p className="text-align-center full-width">{details.products}</p>
      </div>
      <div className="p-4 table-border po-status-box">
        <p
          className="xetgo-font-mini bolder status-pill flex-row align-items-center justify-content-center"
          style={{
            backgroundColor:
              CLIENT_PO_PROPERTY_MAP[details.status].backgroundColor,
            color: CLIENT_PO_PROPERTY_MAP[details.status].color,
          }}
        >
          {details.status}
        </p>
      </div>
      <p className="p-12 enquiry-by-container table-border po-client-tab-field">
        {details.poc_name},{" "}
        <span className="sub-title">{details.company_name}</span>
      </p>
    </div>
  );
};

export default PurchaseListCard;
