import { Navigate, Route, Routes } from "react-router-dom";
import routes from "./Routes";
import { useDispatch, useSelector } from "react-redux";
import {
  isAuthenticated,
  isUserLoading,
  selectAuthToken,
} from "../redux/user/userSelector";
import { useEffect, useState } from "react";
import { JWT_TOKEN, setCookie } from "../services/cookieService";
import { breakPointObserver, ScreenSizes } from "./BreakpointObserver";
import SideNav from "../components/SideNav/SideNav";
import Header from "../components/Header/Header";
import { signInUser } from "../redux/user/userAction";
import { isNullOrUndefined } from "./Helper";

const ActiveRoutes = () => {
  const dispatch = useDispatch();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [breakpoint, setBreakpoint] = useState("");
  const isUserAuthenticated: boolean = useSelector(isAuthenticated);
  const userLoading = useSelector(isUserLoading);
  const [loadContent, setLoadContent] = useState(true);
  const authToken: string | undefined | null = useSelector(selectAuthToken);

  useEffect(() => {
    breakPointObserver(setBreakpoint);
  }, [breakpoint]);

  useEffect(() => {
    if (authToken) {
      dispatch(
        signInUser({
          jwt_token: authToken,
        }),
      );
      setCookie(JWT_TOKEN, authToken);
    }
    setLoadContent(false);
  }, [authToken]);

  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateWindowWidth);
    return () => {
      window.removeEventListener("resize", updateWindowWidth);
    };
  }, [windowWidth]);

  if (!loadContent && (!userLoading || isNullOrUndefined(authToken))) {
    if (isUserAuthenticated) {
      return (
        <>
          {[
            ScreenSizes.LAPTOP,
            ScreenSizes.MINI_TABLET,
            ScreenSizes.TABLET,
            ScreenSizes.MOBILE,
          ].includes(breakpoint as ScreenSizes) && <Header></Header>}
          <div
            className="flex-row "
            style={{
              height: `${
                ScreenSizes.DESKTOP === breakpoint
                  ? "100vh"
                  : "calc(100vh - 48px)"
              }`,
            }}
          >
            {ScreenSizes.DESKTOP === breakpoint && <SideNav></SideNav>}
            <Routes>
              {routes.map((route, index) =>
                route.protected ? <Route key={index} {...route} /> : null,
              )}

              <Route
                key={routes.length}
                path="/notfound"
                element={<div> PAGE NOT FOUND </div>}
              />
              <Route path="*" element={<Navigate to={`/profile`} replace />} />
            </Routes>
          </div>
        </>
      );
    } else {
      return (
        <Routes>
          {routes.map((route, index) =>
            !route.protected ? <Route key={index} {...route} /> : null,
          )}

          <Route
            key={routes.length}
            path="/notfound"
            element={<div> PAGE NOT FOUND </div>}
          />
          <Route path="*" element={<Navigate to="/signin" replace />} />
        </Routes>
      );
    }
  } else {
    return <></>;
  }
};

export default ActiveRoutes;
