import React, { useEffect, useRef, useState } from "react";

import "./CheckListPropertyCard.scss";
import OutsideAlerter from "../../utils/OutsideClickDetector";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";

interface CheckListProps {
  handleClose: () => void;
  scheduleId: number;
  quoteProductId: number;
}

interface CheckListProperties {
  id: number;
  key: string;
  unit: string;
  value: string | null;
  delivery_quality_check_mapping_id: number | null;
  client_value: string | null;
  vendor_value: string | null;
}

const CheckListPropertyCard: React.FC<CheckListProps> = ({
  handleClose,
  scheduleId,
  quoteProductId,
}) => {
  const [allProperties, setAllProperties] = useState<CheckListProperties[]>([]);

  const getDeliveryPropertyValues = async () => {
    try {
      const { data } = await ApiService().client.get(
        URL.CHECKLIST.GET_PROPERTY_VALUES,
        {
          params: { deliveryId: scheduleId, quoteProductId: quoteProductId },
        }
      );
      setAllProperties(data.property_details);
    } catch (error) {
      console.error("Error fetching property values", error);
      return [];
    }
  };

  useEffect(() => {
    getDeliveryPropertyValues();
  }, []);
  const [isEditingIndex, setIsEditingIndex] = useState(-1);
  const vendorValueRef = useRef<(HTMLParagraphElement | null)[]>([]);

  const handleFocus = (index: number) => {
    setIsEditingIndex(index);
    vendorValueRef.current[index]?.focus();
  };

  const handleBlur = (index: number) => {
    setIsEditingIndex(-1);
  };

  const handleVendorValueSubmit = async (
    deliveryQualityCheckMappingId: number,
    vendorValue: string | null,
    qualityCheckId: number
  ) => {
    try {
      const { data } = await ApiService().client.put(
        URL.CHECKLIST.UPDATE_VENDOR_VALUE,
        {
          deliveryQualityCheckMappingId: deliveryQualityCheckMappingId,
          vendorValue: vendorValue,
          scheduleId: scheduleId,
          qualityCheckId: qualityCheckId,
        }
      );
    } catch (error) {
      console.error("Error updating vendor value", error);
      return [];
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement>,
    index: number,
    deliveryQualityCheckMappingId: number,
    qualityCheckId: number
  ) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleVendorValueSubmit(
        deliveryQualityCheckMappingId,
        e.currentTarget.textContent,
        qualityCheckId
      );
      setIsEditingIndex(-1);
      vendorValueRef.current[index]?.blur();
    }
  };

  return (
    <div
      onClick={(e) => {
        handleClose();
      }}
      className="property-backdrop-container-main flex-row align-items-center justify-content-center"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="checklist-dialog-box"
      >
        <div className="property-checklist-main-container">
          <div className="property-checklist-upper-container p-12 flex-row ">
            <p className="xetgo-font-tag bold">Properties </p>
            {allProperties.length > 0 ? (
              <p className="subtitle xetgo-font-tag bold">Standard </p>
            ) : (
              <p></p>
            )}

            <div className="close-container flex-row align-items-center justify-content-space-between">
              {allProperties.length > 0 ? (
                <p className="subtitle xetgo-font-tag bold">Myself </p>
              ) : (
                <p></p>
              )}

              <img
                onClick={handleClose}
                className="cursor-pointer"
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231012084858-close.svg"
                alt="close-icon"
                height={16}
                width={16}
              />
            </div>
          </div>

          {allProperties.length === 0 && (
            <div className="empty-property flex-column gap-8 align-items-center justify-content-center xetgo-font-tag bold">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240327113954-Receipt.svg"
                alt="empty-file-icon"
                height={24}
                width={24}
              />
              <p>No Properties Found</p>
            </div>
          )}
          {allProperties.length > 0 && (
            <div className="property-checklist-lower-container flex-column gap-4 p-8">
              {allProperties.map((property, propertyIndex) => {
                return (
                  <div
                    key={propertyIndex}
                    className={`property-check-container xetgo-font-tag flex-row `}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <p className="property-key px-16 py-8 bold">
                      {property.key} {property.unit ? `(${property.unit})` : ""}
                    </p>
                    <div className="flex-row gap-4">
                      <p className="property-value px-16 py-8 flex-1">
                        {property.value}
                      </p>

                      <p
                        ref={(el) =>
                          (vendorValueRef.current[propertyIndex] = el)
                        }
                        className={`px-16 py-8 vendor-value flex-row align-items-center border-box flex-1 justify-content-center xetgo-font-tag flex-1 ${
                          isEditingIndex === propertyIndex &&
                          "vendor-editing-value"
                        } `}
                        contentEditable={true}
                        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                          handleKeyDown(
                            e,
                            propertyIndex,
                            property.delivery_quality_check_mapping_id || -1,
                            property.id
                          );
                        }}
                        onFocus={() => handleFocus(propertyIndex)}
                        onBlur={() => handleBlur(propertyIndex)}
                        dangerouslySetInnerHTML={{
                          __html: property.vendor_value || "",
                        }}
                      ></p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CheckListPropertyCard;
