import React, { useEffect, useState } from "react";
import "./ProfileFactoryMachines.scss";
import { useSelector } from "react-redux";
import { selectVendorMachines } from "../../redux/user/userSelector";
import XetModal from "../shared/xet-modal/XetModal";
import AddMachinePopup from "../AddMachinePopup/AddMachinePopup";

const ProfileFactoryMachines = () => {
  const machines = useSelector(selectVendorMachines);
  const [productMachineEdit, setProductMachineEdit] = useState(false);

  return (
    <div className="profile-factory-machines-container">
      <div className="top-factory-machines-header flex-row justify-content-space-between px-16 py-12">
        <p className="xetgo-font-tag bold" style={{ color: "#484848" }}>
          Machines
        </p>
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240506100306-pen-square+%281%29.svg"
          width={16}
          height={16}
          className="cursor-pointer"
          onClick={() => setProductMachineEdit(true)}
        />
      </div>
      {machines && machines.length > 0 && (
        <div className="factory-machines-details p-16 flex-row gap-6 flex-wrap">
          {machines.map((machine, index) => {
            return (
              <p key={index} className="product-tag px-8 py-4 xetgo-font-tag">
                {machine}
              </p>
            );
          })}
        </div>
      )}
      <XetModal
        backdropClose={true}
        open={productMachineEdit}
        handleClose={() => setProductMachineEdit(false)}
      >
        <AddMachinePopup onCancel={() => setProductMachineEdit(false)} />
      </XetModal>
    </div>
  );
};
export default ProfileFactoryMachines;
