import React from "react";
import "./SkuQualityCheckCard.scss";

interface SkuQualityCheckCardProps {
  index: number;
  qualityCheck: {
    id: number;
    key: string;
    unit: string | null;
    value: string | null;
    sku_id: number;
  };
}
const SkuQualityCheckCard: React.FC<SkuQualityCheckCardProps> = ({
  qualityCheck,
  index,
}) => {
  return (
    <div
      key={index}
      className="quality-check-card-main-container quality-check-grid"
    >
      <p className="index flex-row align-items-center p-12">
        {(index.toString().length === 2
          ? "0"
          : index.toString().length > 2
          ? ""
          : "00") +
          (index + 1)}
      </p>
      <div className="flex-row align-items-center">
        <div className="key-main-container flex-row align-items-center justify-content-space-between cursor-pointer p-12 flex-1 gap-8">
          <p
            suppressContentEditableWarning={true}
            className="parameter cursor-pointer flex-1 bold"
          >
            {qualityCheck.key}
          </p>
        </div>
        <div className="p-12 unit-container">
          <input
            key={qualityCheck.id}
            className={`quality-check-input full-width unit px-16 py-8 border-box xetgo-font-tag`}
            value={qualityCheck.unit || ""}
            disabled
          />
        </div>
      </div>
      <div className="p-12">
        <input
          key={qualityCheck.id}
          className={`quality-check-input property-value px-16 full-width py-8 border-box xetgo-font-tag`}
          value={qualityCheck.value || ""}
          disabled
        />
      </div>
    </div>
  );
};

export default SkuQualityCheckCard;
