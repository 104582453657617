import React, { useEffect, useState } from "react";
import "./PurchaseOrderDetail.scss";
import { commaFormat } from "../../utils/Helper";
import PurchaseRequestProduct from "../../components/PurchaseRequestProduct/PurchaseRequestProduct";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";
import { useNavigate, useParams } from "react-router-dom";
import { PurchaseOrderDetailSchema } from "../../models/PurchaseOrder";
import { CLIENT_PO_PROPERTY_MAP } from "../../constants/PurchaseStatus.data";
import PurchaseStatusDropdown from "../../components/PurchaseStatusDropdown/PurchaseStatusDropdown";
import CommentSection from "../../components/CommentSection/CommentSection";

const PurchaseOrderDetail: React.FC = () => {
  const [poDetails, setPoDetails] = useState<PurchaseOrderDetailSchema>();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [showCommentSection, setShowCommentSection] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getPoDetails();
  }, []);

  const getPoDetails = async () => {
    try {
      if (id) {
        const { data } = await ApiService().client.get(
          URL.PURCHASE.DETAIL(parseInt(id))
        );
        setPoDetails(data.procurement_details);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updateStatus = async (value: string) => {
    setOpenDropdown(false);
    try {
      if (id) {
        const { data } = await ApiService().client.put(
          URL.PURCHASE.DETAIL(parseInt(id)),
          {
            status: value,
          }
        );
        setPoDetails((val) => {
          if (val) {
            val.status = data.status;
            val.available_statuses = data.available_statuses;
          }
          return val;
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleClose = () => {
    setShowCommentSection(false);
  };

  return (
    <>
      {poDetails && id && (
        <>
          <div className="purchase-order-detail-page full-width flex-column">
            <div className="po-detail-upper-bar px-32 py-12 xetgo-font-tag">
              <p>
                <span
                  onClick={() => navigate("/purchase-orders")}
                  className="cursor-pointer"
                >
                  Purchase Orders /
                </span>
                <span className="sub-title bold"> {poDetails.enquiry_id}</span>
              </p>
            </div>
            <div className="purchase-order-lower-main-container p-16 flex-column gap-12">
              <div className="po-multi-boxes gap-12">
                <div className=" po-enquiry-id-box upper-big-boxes flex-column gap-12 px-16 py-12 xetgo-font-tag justify-content-space-between">
                  <img
                    height={16}
                    width={16}
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240521092955-forklift.svg"
                    alt="lift-icon"
                  />
                  <div className="inner-text-box">
                    <p style={{ color: "#777777" }} className="bold">
                      Purchase Order
                    </p>
                    <p className="po-box-title xetgo-font-h3 box-title">
                      {poDetails.enquiry_id}
                    </p>
                  </div>
                </div>
                <div className=" upper-big-boxes flex-column gap-12 px-16 py-12 xetgo-font-tag justify-content-space-between">
                  <img
                    height={16}
                    width={16}
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240521094417-banknote.svg"
                    alt="cash-icon"
                  />
                  <div className="inner-text-box">
                    <p className="bold sub-title">PO Value</p>
                    <p className="xetgo-font-h3 box-title">
                      ₹ {commaFormat(poDetails.po_value.toString())}
                    </p>
                  </div>
                </div>
                <div className="upper-big-boxes flex-column gap-12 px-16 py-12 xetgo-font-tag justify-content-space-between">
                  <img
                    height={16}
                    width={16}
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240516052108-user.svg"
                    alt="user-icon"
                  />
                  <div className="inner-text-box">
                    <p className="bold sub-title">Client</p>
                    <p className="xetgo-font-h3 box-title">
                      {poDetails?.client.poc_name},{" "}
                      <span className="xetgo-font-tag company-name">
                        {poDetails.client.company_name}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="flex-column gap-4">
                  <div className=" upper-big-boxes flex-column gap-12 px-16 py-12 xetgo-font-tag">
                    <img
                      height={16}
                      width={16}
                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240521092955-forklift.svg"
                      alt="lift-icon"
                    />
                    <div className="inner-text-box">
                      <p className="xetgo-font-h3 box-title">
                        ₹ {poDetails?.city}
                      </p>
                    </div>
                  </div>
                  <div className="purchase-order-status-main-container flex-row align-items-center gap-2">
                    <p
                      style={{
                        background:
                          CLIENT_PO_PROPERTY_MAP[poDetails.status]
                            .backgroundColor,
                        color: CLIENT_PO_PROPERTY_MAP[poDetails.status].color,
                      }}
                      className="flex-1 py-10 current-status xetgo-font-mini bolder"
                    >
                      {poDetails?.status}
                    </p>
                    {poDetails.available_statuses &&
                      poDetails.available_statuses.length > 0 && (
                        <div
                          style={{
                            background:
                              CLIENT_PO_PROPERTY_MAP[poDetails.status]
                                .backgroundColor,
                            color:
                              CLIENT_PO_PROPERTY_MAP[poDetails.status].color,
                          }}
                          onClick={() => setOpenDropdown((val) => !val)}
                          className="px-12 py-14 cursor-pointer status-dropdown-container flex-row align-items-center jusitfy-content-center"
                        >
                          <img
                            height={4}
                            width={8}
                            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231114094003-Vector+%28Stroke%29.svg"
                            alt="dropdown-arrow"
                          />
                        </div>
                      )}
                    {openDropdown && (
                      <PurchaseStatusDropdown
                        statuses={poDetails.available_statuses}
                        updateStatus={updateStatus}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="product-list-main-container flex-1">
                <div className="product-list-heading-container xetgo-font-tag bold">
                  <p className="index py-12 px-16"></p>
                  <div className="flex-row gap-8 align-items-center py-12 px-16">
                    <img
                      height={12}
                      width={12}
                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240520063009-file-box+%282%29.svg"
                      alt="product-box-icon"
                    />
                    <p>Products</p>
                  </div>
                  <div className="flex-row gap-8 align-items-center py-12 px-16 ppu">
                    <img
                      height={12}
                      width={12}
                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240521112504-tabler-icon-brand-codepen.svg"
                      alt="icon"
                    />
                    <p>PPU</p>
                  </div>
                  <div className="flex-row gap-8 align-items-center py-12 px-16">
                    <img
                      height={12}
                      width={12}
                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240521112354-boxes.svg"
                      alt="boxes-icon"
                    />
                    <p>Quantity</p>
                  </div>
                  <div className="flex-row gap-8 align-items-center py-12 px-16">
                    <img
                      height={12}
                      width={12}
                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240520062831-rupee+1.svg"
                      alt="rupee-icon"
                    />
                    <p>PO Value</p>
                  </div>
                </div>
                <div className="product-list-container">
                  {poDetails.products.map((product: any, index: number) => {
                    return (
                      <PurchaseRequestProduct
                        productDetail={product}
                        index={index}
                        key={index}
                        po_id={parseInt(id)}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          {!showCommentSection && (
            <div
              className="purchase-comment-icon p-12 cursor-pointer"
              onClick={() => setShowCommentSection(true)}
            >
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240117070437-chatbubbles.svg"
                alt="comment"
                width={24}
                height={24}
              />
            </div>
          )}
          {showCommentSection && (
            <div
              onClick={() => setShowCommentSection(false)}
              className="purchase-comment-section-container"
            >
              <CommentSection
                source={"PURCHASE"}
                quote_id={poDetails.id}
                handleClose={handleClose}
                clientPocName={poDetails.client.poc_name}
                companyName={poDetails.client.company_name}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default PurchaseOrderDetail;
