import React, { RefObject, useEffect, useRef, useState } from "react";
import "./SkuDetailCard.scss";
import { SkuDetailSchema } from "../../models/SkuListSchema";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import { useSelector } from "react-redux";
import { getUserWarehouseAddresses } from "../../redux/user/userSelector";
import OutsideAlerter from "../../utils/OutsideClickDetector";
import { FilterOptionSchema } from "../Filter/filter.data";
import { toastOptions } from "../../utils/toast";
import { toast } from "react-toastify";

interface SkuDetailProps {
  sku: SkuDetailSchema;
  handleUpdate: (data: SkuDetailSchema) => void;
}

const SkuDetailCard: React.FC<SkuDetailProps> = ({ sku, handleUpdate }) => {
  const userWarehouses = useSelector(getUserWarehouseAddresses);
  const [skuDetail, setSkuDetail] = useState(sku);
  const [skuName, setSkuName] = useState(sku.sku_name);
  const [description, setDescription] = useState(sku.description);
  const [selectedAddress, setSelectedAddress] = useState<number[]>([]);
  const skuNameRef = useRef<HTMLParagraphElement>(null);
  const [addLocation, setAddLocation] = useState(false);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const [editingSku, setEditingSku] = useState(false);
  const [showAddTag, setShowAddTag] = useState(false);
  const [tagData, setTagData] = useState<FilterOptionSchema[]>([]);
  const [skuTags, setSkuTags] = useState<FilterOptionSchema[] | []>([]);
  const [filterSkuTags, setFilterSkuTags] = useState<FilterOptionSchema[] | []>(
    []
  );

  const addresses = userWarehouses.filter(
    (warehouseAdd) =>
      !skuDetail.locations.some((selectAdd) => selectAdd.id === warehouseAdd.id)
  );

  return (
    <div className="sku-detail-card-main-container">
      <div className="name-description-container xetgo-font-tag">
        <div className="flex-row justify-content-space-between p-12 xetgo-font-tag">
          <p className="sku-name xetgo-font-caption bolder">{sku?.sku_name}</p>
          <p className="uuid px-6 py-4 bold">{skuDetail?.uuid}</p>
        </div>
        <div className="flex-row p-12 justify-content-space-between gap-10">
          <div className="description">{skuDetail?.description}</div>
        </div>
      </div>
      <div className="all-tags-container flex-row gap-4 p-12 xetgo-font-mini align-items-center">
        {skuDetail &&
          skuDetail.tags.length > 0 &&
          skuDetail.tags.map((tag, index) => (
            <div
              key={index}
              className="tag-pill px-6 py-4 flex-row gap-4 align-items-center justify-content-center bold"
            >
              <p>{tag.name}</p>
            </div>
          ))}
      </div>
      <div className="artwork-main-container p-12 xetgo-font-tag flex-column gap-12">
        <p>Artwork:</p>
        <div className="flex-row gap-4">
          {skuDetail &&
            skuDetail.artworks.length > 0 &&
            skuDetail.artworks.map((art, index) => (
              <div
                key={index}
                className="artwork-pill py-4 px-8 flex-row gap-6 align-items-center "
              >
                <a
                  style={{ color: "#5151ec" }}
                  href={art.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {art.name}
                </a>
              </div>
            ))}
        </div>
      </div>
      <div className="location-main-container  flex-column gap-12 xetgo-font-tag p-12">
        <div
          className="flex-row align-items-center justify-content-space-between"
          style={{ height: "26px" }}
        >
          <p>Location:</p>
        </div>
        {skuDetail && skuDetail.locations.length > 0 && (
          <div className="all-location-container flex-row gap-8">
            {skuDetail.locations.map((location, index) => (
              <div key={index} className="location-card xetgo-font-tag">
                <p className="city bolder p-8 flex-row align-items-center justify-content-space-between">
                  {location.address_city}
                </p>
                <div className="p-8 flex-column gap-34 flex-1 justify-content-space-between">
                  <p className="line-pin-code flex-column gap-4">
                    {location.address_line}{" "}
                    <span>{location.address_pincode}</span>
                  </p>
                  <div className="flex-column gap-4">
                    <p className="poc-name">
                      {location.poc_name}, {location.poc_number}
                    </p>
                    <p>{location.gstin || ""}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="image-main-container flex-column gap-12 xetgo-font-tag p-12">
        <div className="flex-row align-items-center justify-content-space-between">
          <p>Images:</p>
        </div>
        <div className="all-images-container flex-row gap-8">
          {skuDetail &&
            skuDetail.locations.length > 0 &&
            skuDetail.attachments.map((attachment, index) => (
              <div
                key={index}
                className="sku-image-container position-relative"
              >
                <a
                  href={attachment.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="sku-detail-image cursor-pointer"
                    height={61}
                    width={61}
                    src={attachment.url}
                    alt="images"
                  />
                </a>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SkuDetailCard;
