import React, { useEffect, useState } from "react";
import "./VendorStats.scss";
import { useSelector } from "react-redux";
import { selectVendorStats } from "../../redux/user/userSelector";

const VendorStats = () => {
  const vendorStats = useSelector(selectVendorStats);

  return (
    <div className="vendor-stats-container">
      <div className="total-order-container py-16 px-24 flex-column justify-content-flex-end gap-4">
        <p className="stats-value bold" style={{ color: "#009696" }}>
          ₹ {vendorStats.total_orders}
        </p>
        <p className="xetgo-font-tag" style={{ color: "#47B9B9" }}>
          Total Order Value
        </p>
      </div>
      <div className="quotes-recieved-container py-16 px-24 flex-column justify-content-space-between gap-4">
        <div className="flex-row gap-4 align-items-center">
          <p className="xetgo-font-tag" style={{ color: "#9E6B00" }}>
            Quote List
          </p>
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240506095647-arrow-right.svg"
            width={12}
            height={12}
            alt="right-arrow"
          />
        </div>
        <div className="flex-column gap-4">
          <p className="stats-value bold" style={{ color: "#AA5200" }}>
            {vendorStats.total_quotes}
          </p>
          <p className="xetgo-font-tag" style={{ color: "#CA935E" }}>
            Quotes Received
          </p>
        </div>
      </div>
      <div className="po-list-container py-16 px-24 flex-column justify-content-space-between gap-4">
        <div className="flex-row gap-4 align-items-center">
          <p className="xetgo-font-tag" style={{ color: "#8700B7" }}>
            PO List
          </p>
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240506095647-arrow-right+%281%29.svg"
            width={12}
            height={12}
            alt="right-arrow"
          />
        </div>
        <div className="flex-column gap-4">
          <p className="stats-value bold" style={{ color: "#111111" }}>
            {vendorStats.total_pos}
          </p>
          <p className="xetgo-font-tag" style={{ color: "#888888" }}>
            Ongoing PO
          </p>
        </div>
      </div>
      <div className="next-delivery-container py-16 px-24 flex-column justify-content-flex-end gap-4">
        <p className="stats-value bold" style={{ color: "#03A156" }}>
          {vendorStats.nextDeliveryDate}
        </p>
        <p className="xetgo-font-tag" style={{ color: "#76CAA2" }}>
          Next Delivery
        </p>
      </div>
    </div>
  );
};
export default VendorStats;
