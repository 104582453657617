import React, { useState } from "react";
import "./ProfileAddress.scss";
import XetModal from "../shared/xet-modal/XetModal";
import ProfileAddAddressPopup from "../ProfileAddAddressPopup/ProfileAddAddressPopup";
import { useSelector } from "react-redux";
import { userDetails } from "../../redux/user/userSelector";

const ProfileAddress = () => {
  const user = useSelector(userDetails);
  const [profileAddressPopup, setProfileAddressPopup] = useState(false);
  const [addressIndex, setAddressIndex] = useState(0);
  return (
    <>
      <div className="profile-address-container">
        <div className="top-address-header flex-row justify-content-space-between px-16 py-12">
          <p className="xetgo-font-tag bold" style={{ color: "#484848" }}>
            Addresss
          </p>
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240506100306-pen-square+%281%29.svg"
            width={16}
            height={16}
            className="cursor-pointer"
            onClick={() => setProfileAddressPopup(true)}
          />
        </div>
        {user.addresses.length > 0 && (
          <div className="address-details p-16 flex-column gap-16">
            <div className="header-name-container flex-row gap-4">
              {user.addresses.map((address, index) => {
                return (
                  <p
                    key={index}
                    className={`header-name xetgo-font-tag py-2 px-12 cursor-pointer ${
                      addressIndex === index && "selected-header-name "
                    }`}
                    onClick={() => setAddressIndex(index)}
                  >
                    {address.city}
                  </p>
                );
              })}
            </div>
            <p className="xetgo-font-tag" style={{ color: "#8F8F8F" }}>
              {`${user.addresses[addressIndex].address_poc.poc_name}, ${user.addresses[addressIndex].address_poc.contact_number}`}
            </p>
            <p
              className="xetgo-font-tag"
              style={{ color: "#8F8F8F", marginTop: "-12px" }}
            >
              {`${user.addresses[addressIndex].address_line}, ${user.addresses[addressIndex].pincode}, ${user.addresses[addressIndex].city}, ${user.addresses[addressIndex].state}, ${user.addresses[addressIndex].country}`}
            </p>
          </div>
        )}
      </div>
      <XetModal
        backdropClose={true}
        open={profileAddressPopup}
        handleClose={() => setProfileAddressPopup(false)}
      >
        <ProfileAddAddressPopup
          onCancel={() => setProfileAddressPopup(false)}
        />
      </XetModal>
    </>
  );
};
export default ProfileAddress;
