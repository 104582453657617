import React, { useRef, useState } from "react";
import "./QuoteListProductCard.scss";
import { QUOTE_STATUS_PROPERTY_MAP } from "../../constants/Quotes.data";
import { Files, QuoteListProduct } from "../../models/Quote";
import QuoteStatusDropdown from "../QuoteStatusDropdown/QuoteStatusDropdown";
import QuoteListProductDetailModal from "../QuoteListProductDetailModal/QuoteListProductDetailModal";
import XetModal from "../shared/xet-modal/XetModal";

interface QuoteListProductCardProps {
  quote_id: number;
  productDetail: QuoteListProduct;
  statusDropdownQpmId: number;
  editLeadTime: number;
  editPrice: number;
  handlePriceLeadChange: (cat: string, index: number) => void;
  updateVendorDetail: (cat: string, value: string, qpvm_id: number) => void;
  handleStatusDropdownExpansion: (index: number) => void;
}
const QuoteListProductCard: React.FC<QuoteListProductCardProps> = ({
  quote_id,
  productDetail,
  statusDropdownQpmId,
  editLeadTime,
  editPrice,
  handlePriceLeadChange,
  updateVendorDetail,
  handleStatusDropdownExpansion,
}) => {
  const leadRef = useRef<null | HTMLDivElement>(null);
  const priceRef = useRef<null | HTMLDivElement>(null);
  const [openProductDetailModal, setOpenProductDetailModal] =
    useState<boolean>(false);

  const priceLeadChange = (cat: string) => {
    handlePriceLeadChange(cat, productDetail.qpvm_id);
    if (cat === "price_per_unit") {
      setTimeout(() => {
        priceRef.current?.focus();
      }, 100);
    }
    if (cat === "lead_time") {
      setTimeout(() => {
        leadRef.current?.focus();
      }, 100);
    }
  };

  const handleStatusExpansion = () => {
    handleStatusDropdownExpansion(productDetail.qpvm_id);
  };

  const onEnterPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (editLeadTime === productDetail.qpvm_id && leadRef.current) {
        updateVendorDetail(
          "lead_time",
          leadRef.current.innerHTML,
          productDetail.qpvm_id,
        );
        leadRef.current.innerHTML = "";
      }
      if (editPrice === productDetail.qpvm_id && priceRef.current) {
        updateVendorDetail(
          "price_per_unit",
          priceRef.current.innerHTML,
          productDetail.qpvm_id,
        );
        priceRef.current.innerHTML = "";
      }
    }
  };

  return (
    <div className="product-card-main-container xetgo-font-tag">
      <div className="product-list-card-container">
        <div className="flex-row">
          <p className="product-name-container flex-column gap-4 px-16 py-8 ">
            <span className="bold"> {productDetail.product_name} </span>
            <span className="product-uuid"> {productDetail.uuid} </span>
          </p>

          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240620074716-info.svg"
            alt="info"
            style={{ height: "fit-content", marginTop: "10px" }}
            onClick={(e) => {
              e.stopPropagation();
              setOpenProductDetailModal(true);
            }}
          />
        </div>
        <p className="px-12 py-8 bold">
          {productDetail.quantity + " " + productDetail.unit}
        </p>
        <div className="flex-row" onClick={(e) => e.stopPropagation()}>
          <div
            className="product-lead-time p-4 bold flex-1"
            onClick={() => priceLeadChange("lead_time")}
          >
            {editLeadTime === productDetail.qpvm_id ? (
              <div
                style={{ outline: "none", background: "#ffffff" }}
                className="full-width px-4 border-box xetgo-font-tag "
                ref={leadRef}
                onKeyDown={onEnterPress}
                contentEditable={true}
              ></div>
            ) : (
              <p className="flex-row align-items-center justify-content-space-between px-12">
                <span>
                  {" "}
                  {productDetail.lead_time
                    ? `${productDetail.lead_time}`
                    : "--"}{" "}
                </span>
                <span> days </span>
              </p>
            )}
          </div>
          <div
            className="product-ppu p-4 bold flex-1"
            onClick={() => priceLeadChange("price_per_unit")}
          >
            {editPrice === productDetail.qpvm_id ? (
              <div
                style={{ outline: "none", background: "#ffffff" }}
                className="full-width px-4 border-box xetgo-font-tag"
                ref={priceRef}
                onKeyDown={onEnterPress}
                contentEditable={true}
              ></div>
            ) : (
              <p className="px-12">
                ₹{" "}
                {`${productDetail.price_per_unit ? `${productDetail.price_per_unit}` : "--"}`}
              </p>
            )}
          </div>
        </div>

        <p className="px-12 py-8 bold">
          ₹{" "}
          {productDetail.target_price > 0
            ? productDetail.target_price
            : "Target Price"}
        </p>

        <div
          onClick={(e) => e.stopPropagation()}
          className="product-status-container flex-row gap-2"
        >
          <p
            style={{
              color: QUOTE_STATUS_PROPERTY_MAP[productDetail.status].color,
              background:
                QUOTE_STATUS_PROPERTY_MAP[productDetail.status].backgroundColor,
            }}
            className="product-status flex-row align-items-center justify-content-center border-box px-30 py-8 flex-1"
          >
            {productDetail.status}
          </p>

          {productDetail.available_statuses &&
            productDetail.available_statuses.length > 0 && (
              <div
                onClick={handleStatusExpansion}
                style={{
                  background:
                    QUOTE_STATUS_PROPERTY_MAP[productDetail.status]
                      .backgroundColor,
                }}
                className="product-status-dropdown-container p-6 flex-row align-items-center"
              >
                <img
                  src={
                    QUOTE_STATUS_PROPERTY_MAP[productDetail.status]
                      .dropdown_icon ||
                    "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231114094003-Vector+%28Stroke%29.svg"
                  }
                  alt="dropDown-icon"
                />
              </div>
            )}
          {statusDropdownQpmId == productDetail.qpvm_id && (
            <QuoteStatusDropdown
              statuses={productDetail.available_statuses}
              updateStatus={(key, value) =>
                updateVendorDetail(key, value, productDetail.qpvm_id)
              }
            />
          )}

          {openProductDetailModal && (
            <XetModal
              open={openProductDetailModal}
              handleClose={() => setOpenProductDetailModal(false)}
            >
              <QuoteListProductDetailModal
                quote_id={quote_id}
                sku_id={productDetail.product_id}
                name={productDetail.product_name}
                description={productDetail.description}
              />
            </XetModal>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuoteListProductCard;
