import React, { useEffect, useState } from "react";

import "./QualityCheckDetail.scss";
import { useNavigate, useParams } from "react-router-dom";
import URLS from "../../urls/Urls";
import ApiService from "../../services/apiService";
import { formatDate } from "../../utils/Helper";
import { QUOTE_STATUS_PROPERTY_MAP } from "../../constants/QuotesData";
import QCDetailQualityCheckCard from "../../components/QCDetailQualityCheckCard/QCDetailQualityCheckCard";
import QCDetailQualityCheckTestCard from "../../components/QCDetailQualityCheckTestCard/QCDetailQualityCheckTestCard";

import QualityCheckReport from "../../components/QualityCheckReport/QualityCheckReport";
import { SkuQualityDetailSchema } from "../../models/SkuQualityDetail";

const QualityCheckDetail: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [qualityCheckDetail, setQualityCheckDetail] =
    useState<SkuQualityDetailSchema | null>(null);
  const [approvedQuantity, setApprovedQuantity] = useState<number | string>("");

  useEffect(() => {
    getQualityCheckDetail();
  }, []);

  const getQualityCheckDetail = async () => {
    try {
      const { data } = await ApiService().client.get(
        URLS.QUALITY_CHECK.GET_QUALITY_CHECK_DETAILS(Number(id))
      );
      setQualityCheckDetail(data);
      setApprovedQuantity(data.approved_quantity);
    } catch (error) {
      console.error("Error fetching Quality Check Details", error);
      return [];
    }
  };

  return (
    <div className="quality-check-detail-main-page flex-column full-width">
      {qualityCheckDetail && (
        <>
          {" "}
          <div className="upper-bar">
            <p className="xetgo-font-tag bold px-16 py-12 flex-row gap-4 ">
              <span
                className="cursor-pointer"
                onClick={() => navigate("/quality-checks")}
              >
                Quality Check
              </span>
              <span className="slash">/</span>
              <span className="sub-title xetgo-font-tag">
                {qualityCheckDetail.sku_uuid} - {qualityCheckDetail.sku_name}
              </span>
            </p>
          </div>
          <div className="qc-detail-lower-main-container flex-column gap-12 p-16 flex-1 xetgo-font-tag">
            <div className="company-address-detail-main-container flex-row justify-content-space-between">
              <div className="company-container p-12 flex-column gap-8">
                <div className="flex-column gap-4 xetgo-font-tag">
                  <p className="bold">
                    {qualityCheckDetail.client_details.client.name}
                  </p>
                  <p className="sub-title">
                    {qualityCheckDetail.client_details.client.name}
                  </p>
                </div>
                <div className="flex-column gap-4 xetgo-font-tag">
                  <p className="quantity">
                    {qualityCheckDetail.delivered_quantity}{" "}
                    <span className="unit">{qualityCheckDetail.sku_unit}</span>
                  </p>
                  <p className="quantity">
                    {formatDate(qualityCheckDetail.actual_delivery_date)}
                  </p>
                </div>
              </div>
              <div className="address-container p-12 flex-column gap-16">
                <p className="city bold">{qualityCheckDetail.address.city}</p>
                <p className="flex-column">
                  {qualityCheckDetail.address.address_line}
                  <span>{qualityCheckDetail.address.pincode}</span>
                </p>
              </div>
            </div>
            <div className="qc-sku-detail flex-row xetgo-font-tag align-items-center">
              <div className="flex-row gap-4 align-items-center flex-1 p-12">
                <p className="bold sku-name">{qualityCheckDetail.sku_name}</p>
                <p className="uuid">{qualityCheckDetail.sku_uuid}</p>
              </div>
              <div className="right-sku-info flex-row align-items-center gap-10 flex-1">
                <div className="approved-input-container flex-row ">
                  <input
                    type="number"
                    value={approvedQuantity}
                    className="xetgo-font-tag approved-input px-12 py-6"
                    placeholder="Approved"
                    disabled
                  />
                  <p className="unit">{qualityCheckDetail.sku_unit}</p>
                </div>
                <p className="po-number px-8 py-6">
                  {qualityCheckDetail.po_number}
                </p>
                <a
                  href={qualityCheckDetail.pod}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pod-container px-12 py-6 flex-row align-items-center justify-content-space-between border-box cursor-pointer"
                >
                  <p>POD</p>
                  <img
                    height={12}
                    width={12}
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240523092025-arrow-right+%284%29.svg"
                    alt="forward-arrow"
                  />
                </a>
                <div
                  style={{
                    color:
                      QUOTE_STATUS_PROPERTY_MAP[
                        qualityCheckDetail
                          .procurement_request_product_delivery_status
                      ]?.color ?? "white",
                    backgroundColor:
                      QUOTE_STATUS_PROPERTY_MAP[
                        qualityCheckDetail
                          .procurement_request_product_delivery_status
                      ]?.backgroundColor ?? "#880000",
                  }}
                  className="status-container flex-row align-items-center justify-content-center p-6 border-box"
                >
                  {qualityCheckDetail.procurement_request_product_delivery_status
                    ? qualityCheckDetail.procurement_request_product_delivery_status
                    : "Pending"}
                </div>

                {qualityCheckDetail && (
                  <QualityCheckReport qualityCheckDetail={qualityCheckDetail} />
                )}
              </div>
            </div>

            <div className="quality-check-main-content-container xetgo-font-tag">
              <div className="qc-header quality-check-grid-container">
                <div className="qc-check-left-grid">
                  <p className="px-12 py-6 bold">Parameters</p>
                  <p className="px-12 py-6 bold">Standard</p>
                  <p className="px-12 py-6 bold">Vendor</p>
                </div>
                <p className="px-12 py-6 bold">Observed</p>
              </div>
              {qualityCheckDetail.sku_quality_checks.length > 0 && (
                <div className="all-quality-check-container flex-column">
                  {qualityCheckDetail.sku_quality_checks.map(
                    (qualityCheck, index) => (
                      <QCDetailQualityCheckCard
                        key={index}
                        QualityCheck={qualityCheck}
                      />
                    )
                  )}
                </div>
              )}
            </div>

            <div className="quality-check-main-content-container xetgo-font-tag">
              <div className="qc-header quality-check-grid-container">
                <div className="qc-check-left-grid">
                  <p className="px-12 py-6 bold">Quality Check Test </p>
                </div>
                <p className="px-12 py-6 bold">Test Method</p>
              </div>
              {qualityCheckDetail.sku_quality_check_tests.length > 0 && (
                <div className="all-quality-check-container flex-column">
                  {qualityCheckDetail.sku_quality_check_tests.map(
                    (qualityCheckTest, index) => (
                      <QCDetailQualityCheckTestCard
                        key={index}
                        QualityCheckTest={qualityCheckTest}
                      />
                    )
                  )}
                </div>
              )}
            </div>

            {/* img sections  */}

            <div className="batch-image-content-container flex-column gap-8">
              <p className="px-12 py-10 bold title">Delivered Batch Images </p>
              <div className="batch-all-image-main-container flex-row gap-8">
                {qualityCheckDetail.delivery_attachments
                  .filter((file) => file.status === "ACCEPTED")
                  .map((file, index) => (
                    <div
                      key={index}
                      className="sku-image-container position-relative"
                    >
                      <a href={file.url} target="_blank" rel="noreferrer">
                        <img
                          className="sku-detail-image"
                          height={61}
                          width={61}
                          src={file.url}
                          alt="images"
                        />
                      </a>
                    </div>
                  ))}
              </div>
            </div>

            <div className="batch-image-content-container flex-column gap-8">
              <p className="px-12 py-10 bold title">Damaged Images </p>
              <div className="batch-all-image-main-container flex-row gap-8">
                {qualityCheckDetail.delivery_attachments
                  .filter((file) => file.status === "REJECTED")
                  .map((file, index) => (
                    <div
                      key={index}
                      className="sku-image-container position-relative"
                    >
                      <a href={file.url} target="_blank" rel="noreferrer">
                        <img
                          className="sku-detail-image"
                          height={61}
                          width={61}
                          src={file.url}
                          alt="images"
                        />
                      </a>
                    </div>
                  ))}
              </div>
            </div>
          </div>{" "}
        </>
      )}
    </div>
  );
};

export default QualityCheckDetail;
