import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { userDetails } from "../../redux/user/userSelector";
import { useDispatch } from "react-redux";
import { updateVendorProfileInfoStart } from "../../redux/user/userAction";
import "./AddFactoryImages.scss";

interface AddFactoryImagesProps {
  onCancel: () => void;
}

const AddFactoryImages: React.FC<AddFactoryImagesProps> = ({ onCancel }) => {
  const user = useSelector(userDetails);
  const dispatch = useDispatch();
  const [images, setImages] = useState<File[]>([]);
  const [imageUrl, setImageUrl] = useState<string[]>([]);

  const submitProfileEdit = () => {
    if (user?.id) {
      const formData = new FormData();
      images.forEach((image) => {
        formData.append("files[]", image);
      });
      dispatch(updateVendorProfileInfoStart(formData));
    }
    onCancel();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const files = Array.from(event.target.files);
      setImages((old) => [...old, ...files]);
      files.forEach((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          if (reader.result) {
            const url = reader.result.toString();
            setImageUrl((urls) => [...urls, url]);
          }
        };
      });
    }
  };

  return (
    <div className="profile-factory-image-container">
      <div className="flex-row justify-content-space-between p-16 profile-factory-image-header-container">
        <p className="xetgo-font-button bold">Factory Photos</p>
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240508063250-x.svg"
          alt="close"
          className="cursor-pointer"
          width={16}
          height={16}
          onClick={onCancel}
        />
      </div>
      <div className="factory-image-content-container py-12 px-16 flex-column gap-12">
        <div className="edit-field-container flex-column gap-8">
          <p className="xetgo-font-tag bold" style={{ color: "#999898" }}>
            Photos *
          </p>
          <div className="flex-row gap-12 " style={{ overflow: "scroll" }}>
            {imageUrl.map((url, index) => {
              return (
                <img
                  key={index}
                  src={url}
                  width={100}
                  height={100}
                  alt={`img-${index}`}
                  style={{ borderRadius: "6px" }}
                />
              );
            })}
          </div>
          <div className="upload-image-container p-16 flex-column align-items-center justify-content-center gap-12">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240509111120-Featured+icon.svg"
              width={40}
              height={40}
              alt="cloud-upload"
            />
            <input
              type="file"
              id="select-file"
              onChange={handleFileChange}
              multiple
              style={{ display: "none" }}
              accept=".jpg, .png, .jpeg"
            />
            <div className="flex-column gap-4 align-items-center">
              <label htmlFor="select-file" className="select-file-label">
                <div
                  className="xetgo-font-button bolder cursor-pointer"
                  style={{ color: "#5151EC" }}
                >
                  Click to upload and attach Photos
                </div>
              </label>
              <p className="xetgo-font-tag" style={{ color: "#475467" }}>
                png, jpg
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="factory-image-button-container flex-row justify-content-flex-end p-16">
        <div
          className="cancel-btn py-8 px-16 xetgo-font-tag cursor-pointer"
          style={{ color: "#5151EC", borderRadius: "8px" }}
          onClick={onCancel}
        >
          Cancel
        </div>
        <div
          className="save-btn py-8 px-16 xetgo-font-tag cursor-pointer"
          style={{
            color: "#FFFFFF",
            backgroundColor: "#5151EC",
            borderRadius: "8px",
          }}
          onClick={submitProfileEdit}
        >
          Save
        </div>
      </div>
    </div>
  );
};
export default AddFactoryImages;
