import React, { useEffect, useState } from "react";
import "./SignUp.scss";
import FormInput from "../../components/Loader/FormInput/FormInput";
import { signupUser } from "../../redux/user/userAction";
import { ToastContainer } from "react-toastify";
import {
  ScreenSizes,
  breakPointObserver,
} from "../../utils/BreakpointObserver";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [breakpoint, setBreakpoint] = useState("");

  const formData = {
    email: "",
    password: "",
    companyName: "",
    fullName: "",
    mobileNumber: "",
    designation: "",
  };
  const [responseBody, setResponseBody] = useState(formData);
  const [validForm, setValidForm] = useState(false);

  useEffect(() => {
    breakPointObserver(setBreakpoint);
  }, [breakpoint]);

  useEffect(() => {
    setValidForm(
      responseBody.email !== "" &&
        responseBody.password !== "" &&
        responseBody.companyName !== "" &&
        responseBody.fullName !== "" &&
        responseBody.mobileNumber !== "" &&
        responseBody.designation !== ""
    );
  }, [responseBody]);

  const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    setResponseBody({ ...responseBody, [id]: value });
  };

  const onSubmitHandler = async (event: any) => {
    event.preventDefault();
    dispatch(
      signupUser({
        poc_email: responseBody.email,
        poc_designation: responseBody.designation,
        poc_mobile: responseBody.mobileNumber,
        password: responseBody.password,
        company_name: responseBody.companyName,
        poc_name: responseBody.fullName,
      })
    );
  };

  return (
    <>
      <div className="signup">
        <div className="signup-left flex-column align-items-center">
          <div className="center-signup-content flex-column align-items-center justify-content-space-between">
            <div className="signup-content-container">
              <div className="flex-column left-upper-container-signup gap-24">
                {breakpoint !== ScreenSizes.MOBILE && (
                  <div>
                    <img
                      alt="logo"
                      src="https://xetoolbucket.s3.ap-south-1.amazonaws.com/1690457499905-xetgo_horizontal-1%201.svg"
                    />
                  </div>
                )}
                <div className="flex-column gap-8">
                  <h1 className="detail-heading xetgo-font-h2 m-0 ">
                    {breakpoint === ScreenSizes.MOBILE
                      ? "Welcome to Xetgo"
                      : "Enter your details"}
                  </h1>
                  <p className="detail-sub-heading xetgo-font-button-bold">
                    Setup your profile to continue
                  </p>
                </div>
              </div>
              <div className="signup-left-form-container">
                <form
                  onSubmit={onSubmitHandler}
                  className="signup-form flex-column gap-8"
                >
                  <div className="flex-column gap-8">
                    <FormInput
                      id="email"
                      placeholder="Email Address"
                      inputChangeHandler={inputChangeHandler}
                      value={responseBody.email}
                    />
                    <FormInput
                      id="password"
                      placeholder="Password"
                      type="password"
                      inputChangeHandler={inputChangeHandler}
                      value={responseBody.password}
                    />
                    <FormInput
                      id="companyName"
                      placeholder="Company Name"
                      inputChangeHandler={inputChangeHandler}
                      value={responseBody.companyName}
                    />
                    <FormInput
                      id="fullName"
                      placeholder="Full Name"
                      inputChangeHandler={inputChangeHandler}
                      value={responseBody.fullName}
                    />
                    <FormInput
                      id="mobileNumber"
                      placeholder="Mobile Number"
                      inputChangeHandler={inputChangeHandler}
                      value={responseBody.mobileNumber}
                    />
                    <FormInput
                      id="designation"
                      placeholder="Designation"
                      inputChangeHandler={inputChangeHandler}
                      value={responseBody.designation}
                    />
                  </div>
                  <div className="sign-up-bottom-content flex-column">
                    {breakpoint === ScreenSizes.MOBILE && (
                      <>
                        <div className="signup-left-img-container">
                          <img
                            alt="logo"
                            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240129094742-xetgo_horizontal-1+2.svg"
                          />
                        </div>
                        <div className="flex-row gap-4 xetgo-font-button-bold align-items-center">
                          <p className="xetgo-font-tag">
                            Aleady have an account?
                          </p>
                          <div className="flex-row align-items-center gap-3">
                            <span
                              className="active-sign-in-link cursor-pointer xetgo-font-tag"
                              onClick={() => navigate("/signin")}
                            >
                              Sign In
                            </span>
                            <img
                              height={12}
                              width={12}
                              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240131091744-arrow-right.svg"
                              alt="forward-icon"
                            />
                          </div>
                        </div>

                        <div className="signup-left-account-container">
                          <p className="terms-condition-signup">
                            By clicking “Continue” above you acknowledge that
                            you have read and understood and agree to Xetgo’s{" "}
                            <a href="https://xetgo.co/terms-and-conditions">
                              Terms & Condtions
                            </a>{" "}
                            and{" "}
                            <a href="https://xetgo.co/privacy-policy/">
                              Privacy Policy
                            </a>
                          </p>
                        </div>
                      </>
                    )}
                    <button
                      className={`${
                        validForm ? "valid-form" : ""
                      } sign-up-btn p-12 xetgo-font-tag bold`}
                    >
                      Continue
                    </button>
                    {breakpoint !== ScreenSizes.MOBILE && (
                      <div className="flex-row gap-4 xetgo-font-button-bold align-items-center">
                        <p className="xetgo-font-tag">
                          Aleady have an account?
                        </p>
                        <div className="flex-row align-items-center gap-3">
                          <span
                            className="active-sign-in-link cursor-pointer xetgo-font-tag"
                            onClick={() => navigate("/signin")}
                          >
                            Sign In
                          </span>
                          <img
                            height={12}
                            width={12}
                            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240131091744-arrow-right.svg"
                            alt="forward-icon"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
            {breakpoint !== ScreenSizes.MOBILE && (
              <div className="signup-left-account-container">
                <p className="terms-condition-signup xetgo-font-button bold ">
                  By clicking “Continue” above you acknowledge that you have
                  read and understood and agree to Xetgo’s{" "}
                  <a
                    href="https://xetgo.co/terms-and-conditions"
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                  >
                    Terms & Condtions
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://xetgo.co/privacy-policy/"
                    className="link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </p>
              </div>
            )}
          </div>
        </div>
        {breakpoint !== ScreenSizes.MOBILE && (
          <div className="signup-right"></div>
        )}
      </div>
      <ToastContainer position="top-right" />
    </>
  );
};
export default Signup;
