import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { userDetails } from "../../redux/user/userSelector";
import { useDispatch } from "react-redux";
import { updateVendorProfileInfoStart } from "../../redux/user/userAction";
import "./AddMachinePopup.scss";

interface AddMachinePopupProps {
  onCancel: () => void;
}

const AddMachinePopup: React.FC<AddMachinePopupProps> = ({ onCancel }) => {
  const user = useSelector(userDetails);
  const dispatch = useDispatch();
  const machineRef = useRef<null | HTMLDivElement>(null);
  const [taggedMachines, setTaggedMachines] = useState<string[]>([]);

  const submitProfileEdit = () => {
    if (user?.id) {
      const formData = new FormData();
      formData.set("machines", JSON.stringify(taggedMachines));
      dispatch(updateVendorProfileInfoStart(formData));
    }
    onCancel();
  };

  const onEnterPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey && machineRef.current) {
      e.preventDefault();
      const text = machineRef.current?.innerText;
      if (text) {
        setTaggedMachines((row) => [...row, text]);
        machineRef.current.innerHTML = "";
      }
    }
  };

  return (
    <div className="profile-machine-container">
      <div className="flex-row justify-content-space-between p-16 profile-machine-header-container">
        <p className="xetgo-font-button bold">Profile</p>
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240508063250-x.svg"
          alt="close"
          className="cursor-pointer"
          width={16}
          height={16}
          onClick={onCancel}
        />
      </div>
      <div className="machine-content-container py-12 px-16 flex-column gap-12">
        <div className="edit-field-container flex-column gap-8">
          <p className="xetgo-font-tag bold" style={{ color: "#999898" }}>
            Machines *
          </p>
          <div
            className="flex-row gap-6 machine-list-container p-12 xetgo-font-tag"
            onClick={() => machineRef.current?.focus()}
          >
            {taggedMachines.map((machine, index) => {
              return (
                <p
                  className="py-4 px-8 xetgo-font-tag"
                  key={index}
                  style={{
                    color: "#005A99",
                    background: "#F1FAFF",
                    borderRadius: "6px",
                    height: "fit-content",
                  }}
                >
                  {machine}
                </p>
              );
            })}
            <p
              ref={machineRef}
              className="xetgo-font-tag machine-name-entry"
              contentEditable={true}
              onKeyDown={onEnterPress}
            ></p>
          </div>
          <p className="xetgo-font-tag">Write machinary and press Enter.</p>
        </div>
      </div>
      <div className="machine-button-container flex-row justify-content-flex-end p-16">
        <div
          className="cancel-btn py-8 px-16 xetgo-font-tag cursor-pointer"
          style={{ color: "#5151EC", borderRadius: "8px" }}
          onClick={onCancel}
        >
          Cancel
        </div>
        <div
          className="save-btn py-8 px-16 xetgo-font-tag cursor-pointer"
          style={{
            color: "#FFFFFF",
            backgroundColor: "#5151EC",
            borderRadius: "8px",
          }}
          onClick={submitProfileEdit}
        >
          Save
        </div>
      </div>
    </div>
  );
};
export default AddMachinePopup;
