import React, { useState } from "react";
import "./QuoteListCard.scss";
import QuoteListCardDetail from "../QuoteListCardDetail/QuoteListCardDetail";
import { QuoteListSchema } from "../../models/Quote";
import { formatDateWithYear, formatTime } from "../../utils/date-util";
import CommentSection from "../CommentSection/CommentSection";
import { commaFormat, round } from "../../utils/Helper";
import { QUOTE_STATUS_PROPERTY_MAP } from "../../constants/Quotes.data";

interface QuoteListCardProps {
  index: number;
  quoteListItem: QuoteListSchema;
  expandedIndex: number;
  handleExpansion: (index: number) => void;
}

const QuoteListCard: React.FC<QuoteListCardProps> = ({
  index,
  quoteListItem,
  expandedIndex,
  handleExpansion,
}) => {
  const [showCommentSection, setShowCommentSection] = useState(false);
  const [quotationValue, setQuotationValue] = useState(
    quoteListItem.quotation_value
  );

  const handleComment = () => {
    setShowCommentSection(!showCommentSection);
    if (expandedIndex !== index) {
      handleExpansion(index);
    }
  };

  const handleClose = () => {
    setShowCommentSection(false);
  };

  const updateQuotationValue = (quotationValue: number) => {
    setQuotationValue(quotationValue);
  };

  return (
    <>
      <div
        key={index}
        className="quote-card-container xetgo-font-tag cursor-pointer"
        onClick={() => handleExpansion(index)}
      >
        <p className="index p-12 flex-row align-items-center">{index + 1}</p>
        <div className="flex-column flex-1" style={{ overflowX: "hidden" }}>
          <div className="quote-card-grid flex-1">
            <div className="enquiry-id-pill-container flex-column gap-4 align-items-center p-8 table-border align-items-center">
              <p className="enquiry-id-pill px-12 py-4">
                {quoteListItem.enquiry_id}
              </p>
              <div className="status-container table-border p-4 mobile-status">
                <p
                  style={{
                    color:
                      QUOTE_STATUS_PROPERTY_MAP[quoteListItem.status].color,
                    backgroundColor:
                      QUOTE_STATUS_PROPERTY_MAP[quoteListItem.status]
                        .backgroundColor,
                  }}
                  className="px-10 py-6 status-tag flex-row align-items-center gap-4"
                >
                  <img
                    src={QUOTE_STATUS_PROPERTY_MAP[quoteListItem.status]?.url}
                    alt={quoteListItem.status}
                    width={16}
                    height={16}
                  />
                  {quoteListItem.status}
                </p>
              </div>
            </div>
            <div className="p-12 enquiry-on enquiry-on-container table-border flex-row align-items-center gap-4">
              <p>{formatDateWithYear(quoteListItem.enquiry_on.toString())}</p>
              <span className="sub-title">
                {formatTime(quoteListItem.enquiry_on.toString())}
              </span>
            </div>
            <div className="p-12 table-border flex-row align-items-center gap-10">
              <div className="enquiry-on-container flex-1 flex-row gap-2">
                <p>{quoteListItem.company_poc_name},</p>
                <p className="sub-title">{quoteListItem.company_name}</p>
              </div>
              <img
                className="client-comment"
                onClick={handleComment}
                height={16}
                width={16}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240515094329-messages-square.svg"
                alt="comment-icon"
              />
            </div>
            <div className="p-12 table-border quotation-value-container flex-row align-items-center">
              <p className="flex-row justify-content-space-between full-width">
                <span>
                  {quotationValue > 0
                    ? `₹ ${commaFormat(round(quotationValue, 2).toString())}`
                    : "--"}
                </span>
                <img
                  className="hide-comment"
                  onClick={handleComment}
                  height={16}
                  width={16}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240515094329-messages-square.svg"
                  alt="comment-icon"
                />
              </p>
            </div>
            <div
              onClick={(e) => e.stopPropagation()}
              className="product-count-comment flex-row p-12 justify-content-space-between table-border align-items-center"
            >
              <p>{quoteListItem.total_products}</p>
              <img
                onClick={handleComment}
                height={16}
                width={16}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240515094329-messages-square.svg"
                alt="comment-icon"
              />
            </div>
            <div className="status-container table-border p-4 hide-status">
              <p
                style={{
                  color: QUOTE_STATUS_PROPERTY_MAP[quoteListItem.status].color,
                  backgroundColor:
                    QUOTE_STATUS_PROPERTY_MAP[quoteListItem.status]
                      .backgroundColor,
                }}
                className="px-10 py-6 status-tag flex-row align-items-center gap-4"
              >
                <img
                  src={QUOTE_STATUS_PROPERTY_MAP[quoteListItem.status]?.url}
                  alt={quoteListItem.status}
                  width={16}
                  height={16}
                />
                {quoteListItem.status}
              </p>
            </div>
          </div>
          {expandedIndex === index && (
            <QuoteListCardDetail
              quote_id={quoteListItem.quote_id}
              enquiry_on={quoteListItem.enquiry_on}
              quotation_value={quoteListItem.quotation_value}
              updateQuotationValue={updateQuotationValue}
            />
          )}
        </div>
      </div>
      {showCommentSection && (
        <div
          onClick={() => setShowCommentSection(false)}
          className="comment-section-container"
        >
          <CommentSection
            source={"QUOTE"}
            quote_id={quoteListItem.quote_id}
            handleClose={handleClose}
            clientPocName={quoteListItem.company_poc_name}
            companyName={quoteListItem.company_name}
          />
        </div>
      )}
    </>
  );
};

export default QuoteListCard;
