import React, { useState } from "react";
import "./ProfileAddAddressPopup.scss";
import { useSelector } from "react-redux";
import { userDetails } from "../../redux/user/userSelector";
import { useDispatch } from "react-redux";
import { addVendorAddressStart } from "../../redux/user/userAction";
import { FilterOptionSchema } from "../Filter/filter.data";
import {
  ALL_COUNTRIES,
  VENDOR_ADDRESS_TYPES,
} from "../../constants/Country.data";
import Filter from "../Filter/Filter";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toast";

interface ProfileAddAddressPopupProps {
  onCancel: () => void;
}

const ProfileAddAddressPopup: React.FC<ProfileAddAddressPopupProps> = ({
  onCancel,
}) => {
  const user = useSelector(userDetails);
  const dispatch = useDispatch();
  const [pincodeLoading, setPincodeLoading] = useState(false);
  const [profileDetails, setProfileDetails] = useState({
    alias: "",
    pincode: "",
    address_line: "",
    city: "",
    state: "",
    poc_name: "",
    contact_number: "",
    country: ALL_COUNTRIES.find((val) => val.code === "IN") || ALL_COUNTRIES[0],
    address_type: VENDOR_ADDRESS_TYPES[0],
  });

  const getPincodeDetails = async () => {
    if (!profileDetails.country || !profileDetails.pincode) {
      return;
    }
    try {
      setPincodeLoading(true);
      const options = {
        method: "GET",
        headers: {
          "X-RapidAPI-Key":
            "f96dea3bcamsh4aa42a373e8d194p1f4323jsnc2319becab7e",
          "X-RapidAPI-Host": "global-zip-codes-with-lat-and-lng.p.rapidapi.com",
        },
      };
      const response = await fetch(
        `https://global-zip-codes-with-lat-and-lng.p.rapidapi.com/api/v1/geocode?code=${profileDetails.pincode}&country=${profileDetails.country.code}`,
        options
      );

      const data = await response.json();

      const pincodeDetails = data[0];
      if (pincodeDetails) {
        editField("state", pincodeDetails.state);
        editField("city", pincodeDetails.district);
      }
      setPincodeLoading(false);
      if (data.length <= 0) {
        console.error("Invalid Pincode!");
      }
    } catch (error) {
      console.error("Invalid Pincode!");
    }
  };

  const editField = (key: string, value: string | FilterOptionSchema) => {
    setProfileDetails((val) => {
      return { ...val, [key]: value };
    });
  };

  const submitProfileEdit = () => {
    if (profileDetails.poc_name.length <= 0) {
      toast.error("Please enter POC Name", toastOptions);
      return;
    }
    if (profileDetails.contact_number.length <= 0) {
      toast.error("Please enter Contact Number", toastOptions);
      return;
    }
    if (profileDetails.address_line.length <= 0) {
      toast.error("Please enter Address", toastOptions);
      return;
    }
    if (profileDetails.pincode.length <= 0) {
      toast.error("Please enter Pincode", toastOptions);
      return;
    }
    if (profileDetails.city.length <= 0) {
      toast.error("Please enter City", toastOptions);
      return;
    }
    if (profileDetails.state.length <= 0) {
      toast.error("Please enter  State", toastOptions);
      return;
    }
    dispatch(
      addVendorAddressStart({
        ...profileDetails,
        country: profileDetails.country.name,
        address_type: profileDetails.address_type.name,
        vendor_id: user.id || 1,
      })
    );
    onCancel();
  };

  return (
    <div className="profile-add-address-container">
      <div className="flex-row justify-content-space-between p-16 profile-add-address-header-container">
        <p className="xetgo-font-button bold">Address</p>
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240508063250-x.svg"
          alt="close"
          className="cursor-pointer"
          width={16}
          height={16}
          onClick={onCancel}
        />
      </div>
      <div className="add-address-content-container py-12 px-16 flex-column gap-12">
        <div className="flex-row gap-12 edit-field-row">
          <div className="add-address-field-container flex-column gap-8 flex-1">
            <p className="xetgo-font-tag bold" style={{ color: "#999898" }}>
              Alias Name
            </p>
            <input
              type="text"
              className="add-address-field-value px-12 py-8"
              value={profileDetails.alias}
              onChange={(e) => editField("alias", e.target.value)}
            />
          </div>
          <div className="add-address-field-container flex-column gap-8 flex-1">
            <p className="xetgo-font-tag bold" style={{ color: "#999898" }}>
              Address Type <span style={{ color: "red" }}>*</span>
            </p>
            <Filter
              option={{
                key: "address_type",
                name: "Address Type",
              }}
              optionList={VENDOR_ADDRESS_TYPES}
              multipleSelection={false}
              onSelection={(val, _option) => {
                editField("address_type", val[0]);
              }}
              mode="showNames"
              showPlaceholder={false}
              preSelected={[profileDetails.address_type]}
              direction="left"
              addNew={false}
            />
          </div>
        </div>
        <div className="flex-row gap-12 edit-field-row">
          <div className="add-address-field-container flex-column gap-8 flex-1">
            <p className="xetgo-font-tag bold" style={{ color: "#999898" }}>
              POC Name <span style={{ color: "red" }}>*</span>
            </p>
            <input
              type="text"
              className="add-address-field-value px-12 py-8"
              value={profileDetails.poc_name}
              onChange={(e) => editField("poc_name", e.target.value)}
            />
          </div>
          <div className="add-address-field-container flex-column gap-8 flex-1">
            <p className="xetgo-font-tag bold" style={{ color: "#999898" }}>
              Contact Number <span style={{ color: "red" }}>*</span>
            </p>
            <input
              type="number"
              className="add-address-field-value px-12 py-8"
              value={profileDetails.contact_number}
              onChange={(e) => editField("contact_number", e.target.value)}
            />
          </div>
        </div>
        <div className="add-address-field-container flex-column gap-8 flex-1">
          <p className="xetgo-font-tag bold" style={{ color: "#999898" }}>
            Address Line <span style={{ color: "red" }}>*</span>
          </p>
          <input
            type="text"
            className="add-address-field-value px-12 py-8"
            value={profileDetails.address_line}
            onChange={(e) => editField("address_line", e.target.value)}
          />
        </div>
        <div className="flex-row gap-12 edit-field-row">
          <div className="add-address-field-container flex-column gap-8 flex-1">
            <p className="xetgo-font-tag bold" style={{ color: "#999898" }}>
              Country
            </p>
            <Filter
              option={{
                key: "country",
                name: "Country",
              }}
              optionList={ALL_COUNTRIES}
              multipleSelection={false}
              onSelection={(val, _option) => {
                editField("country", val[0]);
              }}
              mode="showNames"
              showPlaceholder={false}
              preSelected={[profileDetails.country]}
              direction="left"
              addNew={false}
            />
          </div>
          <div className="add-address-field-container flex-column gap-8 flex-1">
            <p className="xetgo-font-tag bold" style={{ color: "#999898" }}>
              Pincode <span style={{ color: "red" }}>*</span>
            </p>
            <input
              type="text"
              className="add-address-field-value px-12 py-8"
              value={profileDetails.pincode}
              onChange={(e) => editField("pincode", e.target.value)}
            />
            {pincodeLoading ? (
              <img
                onClick={() => getPincodeDetails()}
                className="pincode-forward cursor-pointer"
                src="https://i.gifer.com/origin/34/34338d26023e5515f6cc8969aa027bca.gif"
                alt="loader"
                width={16}
                height={16}
              />
            ) : (
              <img
                onClick={() => getPincodeDetails()}
                className="pincode-forward cursor-pointer"
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231130104346-arrow-forward.svg"
                alt="forward"
                width={16}
                height={16}
              />
            )}
          </div>
        </div>
        <div className="flex-row gap-12 edit-field-row">
          <div className="add-address-field-container flex-column gap-8 flex-1">
            <p className="xetgo-font-tag bold" style={{ color: "#999898" }}>
              City
            </p>
            <input
              type="text"
              className="add-address-field-value px-12 py-8"
              value={profileDetails.city}
              onChange={(e) => editField("city", e.target.value)}
            />
          </div>
          <div className="add-address-field-container flex-column gap-8 flex-1">
            <p className="xetgo-font-tag bold" style={{ color: "#999898" }}>
              State
            </p>
            <input
              type="text"
              className="add-address-field-value px-12 py-8"
              value={profileDetails.state}
              onChange={(e) => editField("state", e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="add-address-button-container flex-row justify-content-flex-end p-16">
        <div
          className="cancel-btn py-8 px-16 xetgo-font-tag cursor-pointer"
          style={{ color: "#5151EC", borderRadius: "8px" }}
          onClick={onCancel}
        >
          Cancel
        </div>
        <div
          className="save-btn py-8 px-16 xetgo-font-tag cursor-pointer"
          style={{
            color: "#FFFFFF",
            backgroundColor: "#5151EC",
            borderRadius: "8px",
          }}
          onClick={submitProfileEdit}
        >
          Save
        </div>
      </div>
    </div>
  );
};
export default ProfileAddAddressPopup;
