import React, { useEffect, useState } from "react";
import "./QuoteListCardDetail.scss";
import QuoteListProductCard from "../QuoteListProductCard/QuoteListProductCard";
import {
  Files,
  IVendorPriceMap,
  QuoteDetailSchema,
  QuoteListProduct,
} from "../../models/Quote";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";
import { formatDateWithYear, formatTime } from "../../utils/date-util";

interface QuoteListCardDetailProp {
  quote_id: number;
  enquiry_on: number;
  quotation_value: number;
  updateQuotationValue: (quotationValue: number) => void;
}

const QuoteListCardDetail: React.FC<QuoteListCardDetailProp> = ({
  quote_id,
  enquiry_on,
  quotation_value,
  updateQuotationValue,
}) => {
  const [quoteDetail, setQuoteDetail] = useState<QuoteDetailSchema[]>([]);
  const [statusDropdownQpmId, setStatusDropdownQpmId] = useState(-1);
  const [editLeadTime, setEditLeadTime] = useState(-1);
  const [editPrice, setEditPrice] = useState(-1);
  const [fileAttachments, setFileAttachments] = useState<Files[]>([]);
  const [description, setDescription] = useState("");
  const [vendorPriceMap, setVendorPriceMap] = useState<IVendorPriceMap>({});

  useEffect(() => {
    getQuoteDetail(quote_id).then((r) => {
      return r;
    });

    return () => {
      setQuoteDetail([]);
    };
  }, [quote_id]);

  useEffect(() => {
    let updatedQuotationValue = 0;
    if (quoteDetail) {
      updatedQuotationValue = Object.values(vendorPriceMap).reduce(
        (sum, value) => sum + value.price * value.quantity,
        0,
      );
    }
    updateQuotationValue(updatedQuotationValue);
  }, [vendorPriceMap]);

  const getQuoteDetail = async (quote_id: number) => {
    try {
      const { data } = await ApiService().client.get(
        URL.QUOTES.QUOTE_DETAIL(quote_id),
      );
      const quoteDetailData = data.data;
      setFileAttachments(quoteDetailData[0].attachments);
      setDescription(quoteDetailData[0].quote_description);
      setQuoteDetail(quoteDetailData);
      generateVendorPriceMap(quoteDetailData);
    } catch (e) {
      console.error(e);
    }
  };

  const generateVendorPriceMap = (quoteDetailData: QuoteDetailSchema[]) => {
    const priceMap: IVendorPriceMap = {};
    quoteDetailData.forEach((quoteDetail) => {
      quoteDetail.quote_products.forEach((product) => {
        priceMap[product.qpvm_id] = {
          quantity: product.quantity,
          price: product.price_per_unit,
        };
      });
    });
    setVendorPriceMap(priceMap);
  };

  const handleStatusDropdownExpansion = (qpmId: number) => {
    setStatusDropdownQpmId(qpmId === statusDropdownQpmId ? -1 : qpmId);
  };

  const handlePriceLeadChange = (cat: string, qpmId: number) => {
    if (cat === "price_per_unit") {
      setEditPrice(qpmId);
      setEditLeadTime(-1);
    } else {
      setEditLeadTime(qpmId);
      setEditPrice(-1);
    }
  };

  const updateVendorDetail = async (
    key: string,
    value: string,
    qpvm_id: number,
  ) => {
    const { data } = await ApiService().client.put(
      URL.QUOTES.UPDATE_QUOTE_VENDOR_DETAIL(quote_id, qpvm_id),
      { key, value },
    );
    setQuoteDetail((details) => {
      return details.map((detail) => {
        const products = detail.quote_products.map((product) => {
          if (product.qpvm_id === qpvm_id) {
            return {
              ...product,
              [key]: value,
              available_statuses: data.available_statuses,
            };
          } else {
            return product;
          }
        });
        return { ...detail, quote_products: products };
      });
    });

    if (key === "price_per_unit") {
      setEditPrice(-1);
      const newVendorPriceMap = {
        ...vendorPriceMap,
        [qpvm_id]: {
          price: Number(value),
          quantity: vendorPriceMap[qpvm_id].quantity,
        },
      };
      setVendorPriceMap(newVendorPriceMap);
    } else if (key === "lead_time") {
      setEditLeadTime(-1);
    } else {
      setStatusDropdownQpmId(-1);
    }
  };

  return (
    <div className="expanded-detail-container">
      <div className="expanded-product-list full-width">
        {quoteDetail.map((quoteAddressData, index) => {
          return (
            <div key={`add_title-${index}`}>
              <div className="address-detail-container flex-row align-items-center gap-8 p-12">
                <span className="address-city xetgo-font-tag flex-row align-items-center gap-4">
                  <img
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240505073009-Vector.svg"
                    alt="building-icon"
                  />
                  {quoteAddressData?.address_city}
                </span>

                <span className="detailed-address xetgo-font-tag">
                  {quoteAddressData?.address_line +
                    ", " +
                    quoteAddressData?.pincode}
                </span>
              </div>
              <div className="flex-1 expanded-address-product-list">
                {quoteAddressData?.quote_products?.map(
                  (product: QuoteListProduct, pIndex) => {
                    return (
                      <QuoteListProductCard
                        key={pIndex}
                        quote_id={quote_id}
                        productDetail={product}
                        editLeadTime={editLeadTime}
                        editPrice={editPrice}
                        handlePriceLeadChange={handlePriceLeadChange}
                        statusDropdownQpmId={statusDropdownQpmId}
                        updateVendorDetail={updateVendorDetail}
                        handleStatusDropdownExpansion={
                          handleStatusDropdownExpansion
                        }
                      />
                    );
                  },
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="file-description-container py-12 ">
        {fileAttachments.length > 0 && (
          <div className="flex-row gap-12 p-12 flex-wrap">
            <img
              height={16}
              width={16}
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240516131144-folder+1.svg"
              alt="files-icon"
            />
            <div className="product-attachments flex-row gap-8">
              {fileAttachments.map((file, fileIndex) => {
                return (
                  <a
                    key={`file-index-${fileIndex}`}
                    href={file.url}
                    rel="noreferrer"
                    target="_blank"
                    className="quote-file-name xetgo-font-tag"
                  >
                    {file.name}
                  </a>
                );
              })}
            </div>
          </div>
        )}

        <div
          className="quote-description px-12"
          dangerouslySetInnerHTML={{ __html: description }}
        />

        <div className="py-12 flex-row align-items-center justify-content-space-between quotation-value-visibility ">
          <p style={{ flexDirection: "row" }} className="enquiry-on-container ">
            {formatDateWithYear(enquiry_on.toString())} ,{" "}
            <span className="sub-title">
              {formatTime(enquiry_on.toString())}
            </span>
          </p>

          <p className="flex-row justify-content-space-between quote-val">
            <span> {quotation_value > 0 ? `₹${quotation_value}` : "--"}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default QuoteListCardDetail;
