import "./SkuList.scss";
import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import URLS from "../../urls/Urls";
import ApiService from "../../services/apiService";
import InfiniteScroll from "react-infinite-scroll-component";
import Filter from "../../components/Filter/Filter";
import { FilterOptionSchema } from "../../components/Filter/filter.data";
import { useSelector } from "react-redux";
import { getUserWarehouseAddresses } from "../../redux/user/userSelector";
import AddressDropdown from "../../components/AddressDropdown/AddressDropdown";
import { UserAddress } from "../../redux/user/userTypes";
import { SkuSchema } from "../../models/SkuListSchema";

const SkuList: React.FC = () => {
  const navigate = useNavigate();
  const [searchedText, setSearchedText] = useState("");
  const [skuList, setSkuList] = useState<SkuSchema[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [selectedClients, setSelectedClients] = useState<
    FilterOptionSchema[] | undefined
  >();
  const [hasMore, setHasMore] = useState(true);
  const [clients, setClients] = useState<FilterOptionSchema[] | []>();

  const perPage = 20;

  useEffect(() => {
    getSkuClients();
  }, []);

  useEffect(() => {
    getSkuList(searchedText, 1);
  }, [selectedClients]);

  const debouncedSearch = useMemo(() => {
    return debounce((searchText: string) => {
      getSkuList(searchText, 1);
    }, 500);
  }, [selectedClients]);

  const handleSearchFile = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const searchText = event.target.value;
    setSearchedText(searchText);
    setPageNumber(1);
    debouncedSearch(searchText);
  };

  const getSkuList = async (searchText: string, page: number) => {
    try {
      let clientData: number[] = [];
      if (selectedClients && selectedClients.length > 0) {
        clientData = selectedClients.map((client) => client.id);
      }
      const { data } = await ApiService().client.get(URLS.SKU.GET_SKU_LIST, {
        params: {
          search_string: searchText,
          pageNumber: page,
          perPage: perPage,
          client_ids: clientData,
        },
      });
      if (data.data.length > 0) {
        const updatedSkuList =
          page > 1 ? [...skuList, ...data.data] : data.data;
        setSkuList(updatedSkuList);
        setPageNumber(pageNumber + 1);
        if (updatedSkuList.length == perPage * pageNumber) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      } else {
        setSkuList([]);
        setPageNumber(1);
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching quotes:", error);
      return [];
    }
  };

  const getSkuClients = async () => {
    try {
      const { data } = await ApiService().client.get(URLS.SKU.GET_SKU_CLIENTS);
      setClients(data.clients);
    } catch (error) {
      console.error("Error fetching clients:", error);
      return [];
    }
  };

  const handleNavigationToDetail = (sku_id: number) => {
    navigate(`/skus/${sku_id}`);
  };

  return (
    <div className="sku-content-container">
      <div className="upper-bar">
        <p className="xetgo-font-tag bold px-16 py-12">
          SKU <span className="sub-title xetgo-font-tag">/ List</span>
        </p>
      </div>

      <div className="sku-lower-main-container flex-1 flex-column gap-12 ">
        <div className="sku-upper-container flex-row justify-content-space-between align-items-center p-16">
          <div className="search-bar-container">
            <input
              className="py-8 px-34 search-input border-box xetgo-font-tag"
              value={searchedText}
              onChange={handleSearchFile}
              placeholder="Search ..."
            />
            <img
              height={16}
              width={16}
              className="search-icon "
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240821094925-search.svg"
              alt="search-icon"
            />
          </div>

          <div className="flex-row align-items-center gap-12">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240227122733-list-filter.svg"
              alt="filter"
            />
            <div className="sku-category-filter">
              <Filter
                option={{
                  key: "client",
                  name: "Client",
                }}
                optionList={clients || []}
                multipleSelection={true}
                onSelection={(val, _option) => {
                  if (val.length <= 0) {
                    setSelectedClients(undefined);
                    setPageNumber(1);
                  } else {
                    setSelectedClients(val);
                    setPageNumber(1);
                  }
                }}
                mode="showNames"
                showPlaceholder={true}
                direction="right"
                addNew={false}
                preSelected={selectedClients ? selectedClients : []}
                classname="sku-category-filter"
              />
            </div>
          </div>
        </div>

        <div className="sku-list-lower-container flex-1 flex-column p-16">
          <div className="sku-row sku-list-heading-container">
            <div className="sku-heading-skuId border-box flex-row gap-8 p-12 xetgo-font-tag align-items-center">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240703142823-Frame+1321314713.svg"
                alt="barcode-icon"
                height={12}
                width={12}
              />
              <p>SKU ID</p>
            </div>
            <div className="sku-heading-name border-box flex-row gap-8 p-12 xetgo-font-tag align-items-center">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240703143839-table-properties+%281%29.svg"
                alt="list-icon"
                height={12}
                width={12}
              />
              <p>Name </p>
            </div>
            <div className="sku-heading-skuId border-box flex-row gap-8 p-12 xetgo-font-tag align-items-center">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240520062831-file-box+%281%29.svg"
                alt="barcode-icon"
                height={12}
                width={12}
              />
              <p>Tags</p>
            </div>
            <div className="flex-row gap-8 p-12 xetgo-font-tag align-items-center flex-1">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240710053743-building-2.svg"
                alt="user-icon"
                height={12}
                width={12}
              />
              <p>Description </p>
            </div>
            <div className="flex-row gap-8 p-12 xetgo-font-tag align-items-center flex-1">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240520063058-user.svg"
                alt="user-icon"
                height={12}
                width={12}
              />
              <p>Client</p>
            </div>
          </div>
          <div id="scrollableDiv" className="sku-list-scroll-wrapper flex-1">
            <InfiniteScroll
              dataLength={skuList.length}
              next={() => getSkuList(searchedText, pageNumber)}
              hasMore={hasMore}
              loader={<h4></h4>}
              className="infiniteScrollComponent"
              scrollableTarget="scrollableDiv"
            >
              {skuList.length > 0 &&
                skuList.map((sku, index) => {
                  return (
                    <div
                      onClick={() => handleNavigationToDetail(sku.id)}
                      key={index}
                      className="sku-row sku-products-wrapper flex-row xetgo-font-tag"
                    >
                      <div className="flex-row align-items-center index p-12 border-box">
                        <p className="uuid-container px-12 py-4">{sku.uuid}</p>
                      </div>
                      <p className="sku-name border-box flex-row gap-8 p-12 xetgo-font-tag align-items-center bold">
                        {sku.name}
                      </p>
                      <p className="sku-id border-box flex-row gap-8 p-12 xetgo-font-tag align-items-center">
                        {sku.tags.length > 0 && (
                          <div className="all-tags-container flex-row gap-4">
                            {sku.tags.map((tag, index) => {
                              return (
                                <p
                                  key={index}
                                  className="px-6 py-4 tag-pill xetgo-font-mini"
                                >
                                  {tag.name}
                                </p>
                              );
                            })}
                          </div>
                        )}
                      </p>
                      <p className="flex-1 p-12 p description flex-row align-items-center">
                        {sku.description}
                      </p>
                      <div className="flex-row p-12 gap-8 align-items-center">
                        <img
                          src={sku.client.avatar}
                          width={24}
                          height={24}
                          alt="client-avatar"
                        />
                        <p className="client">{sku.client.name}</p>
                      </div>
                    </div>
                  );
                })}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkuList;
