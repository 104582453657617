import React, { useEffect, useState } from "react";
import OptionList from "../OptionList/OptionList";
import { OptionListSchema } from "../../models/OptionListSchema";
import { useDispatch } from "react-redux";
import { logOutUser } from "../../redux/user/userAction";
import { useLocation, useNavigate } from "react-router-dom";
import { SIDEBAR_DATA } from "../../constants/Sidebar.data";
import { useSelector } from "react-redux";
import { userDetails } from "../../redux/user/userSelector";
import { USER_OPTION_DATA } from "../../constants/HeaderData";
import "./SideNav.scss";

interface SideNavProps {
  showRight?: boolean;
}

const SideNav: React.FC<SideNavProps> = ({ showRight }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(userDetails);
  const [showDropDownOption, setShowDropDownOption] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    SIDEBAR_DATA.forEach((row) => {
      if (location.pathname === row.redirectionUrl) {
        setSelectedOption(row.name);
      }
    });
  }, []);

  const toggleOptionList = (data: any) => {
    if (data.options.length === 0) {
      setSelectedOption(data.name);
      navigate(data.redirectionUrl);
    } else {
      if (showDropDownOption === data.name) {
        setShowDropDownOption("");
      } else {
        setShowDropDownOption(data.name);
      }
    }
  };

  const checkAndNavigateToSku = (optionName: string) => {
    if (optionName == "SKU") {
      navigate("/skus");
    }
  };

  const handleOptionSelection = (option: OptionListSchema) => {
    setSelectedOption(option.name);
    navigate(option.redirectionUrl || "/home");
  };

  const logout = async () => {
    dispatch(logOutUser());
  };

  return (
    <>
      <div
        className={`${
          showRight && "sidenav-right "
        } sidenav-section flex-column justify-content-space-between`}
      >
        <div className="sidenav-left-section flex-column gap-24">
          {!showRight && (
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240212095733-xetgo_horizontal-2.svg"
              alt="logo"
              width={82}
            />
          )}
          <div className="sidenav-options-section flex-column gap-8">
            {SIDEBAR_DATA.map((data, index) => {
              return (
                <span
                  key={`sidebar-options-${index}`}
                  className={`sidenav-option flex-column gap-12 ${
                    showDropDownOption === data.name && "dropdown-opened"
                  }  ${
                    location.pathname === data.redirectionUrl &&
                    "selected-sidenav-option"
                  }`}
                  onClick={() => checkAndNavigateToSku(data.name)}
                >
                  <div
                    className="flex-row justify-content-space-between"
                    onClick={() => toggleOptionList(data)}
                  >
                    <div className="flex-row align-items-center xetgo-font-tag gap-12">
                      <img
                        src={data.iconUrl}
                        alt="cart"
                        width={16}
                        height={16}
                      />
                      {data.name}
                    </div>
                  </div>
                </span>
              );
            })}
          </div>
        </div>

        <div className="sidenav-right-section flex-column xetgo-font-tag gap-12">
          <div
            onClick={() => navigate("/profile")}
            className={`company-profile-box  flex-row align-items-center  px-12 py-10 gap-12 ${
              location.pathname === "/profile" && "selected-sidenav-option"
            }`}
          >
            <img
              height={16}
              width={16}
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240416115603-file-symlink.svg"
              alt="company-profile-icon"
            />
            <p>Company Profile</p>
          </div>
          <div className="logout-container flex-row align-items-center justify-content-space-between  px-12 py-10 ">
            <div className="flex-row gap-12 align-items-center">
              <img
                className="user-avatar"
                height={16}
                width={16}
                src={
                  user.avatar ||
                  "https://media.istockphoto.com/id/1130884625/vector/user-member-vector-icon-for-ui-user-interface-or-profile-face-avatar-app-in-circle-design.jpg?s=612x612&w=0&k=20&c=1ky-gNHiS2iyLsUPQkxAtPBWH1BZt0PKBB1WBtxQJRE="
                }
                alt="profile-photo"
              />
              <p>{user.name}</p>
            </div>
            <img
              onClick={logout}
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240416123231-log-out.svg"
              alt="company-profile-icon"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SideNav;
