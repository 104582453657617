import React, { useEffect, useState } from "react";
import "./FactoryImages.scss";
import { selectVendorImages } from "../../redux/user/userSelector";
import { useSelector } from "react-redux";
import XetModal from "../shared/xet-modal/XetModal";
import AddFactoryImages from "../AddFactoryImages/AddFactoryImages";

const FactoryImages = () => {
  const factoryImages = useSelector(selectVendorImages);
  const [addProductImage, setAddProductImage] = useState(false);
  return (
    <>
      <div className="factory-images-container">
        <div className="top-factory-images-header flex-row justify-content-space-between px-16 py-12">
          <p className="xetgo-font-tag bold" style={{ color: "#484848" }}>
            Factory Images
          </p>
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240506100306-pen-square+%281%29.svg"
            width={16}
            height={16}
            className="cursor-pointer"
            onClick={() => setAddProductImage(true)}
          />
        </div>
        {factoryImages && factoryImages.length > 0 && (
          <div className="factory-images-details p-16 flex-row gap-12 flex-wrap">
            {factoryImages.map((image, index) => {
              return (
                <img
                  key={index}
                  src={image.url}
                  width={100}
                  height={100}
                  style={{ borderRadius: "6px" }}
                />
              );
            })}
          </div>
        )}
      </div>
      <XetModal
        backdropClose={true}
        open={addProductImage}
        handleClose={() => setAddProductImage(false)}
      >
        <AddFactoryImages onCancel={() => setAddProductImage(false)} />
      </XetModal>
    </>
  );
};
export default FactoryImages;
