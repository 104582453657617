import React, { useEffect, useState } from "react";
import { FilterOptionSchema, FilterTypes } from "./filter.data";
import "./Filter.scss";
import FilterDropdown from "../FilterDropdown/FilterDropdown";
import { SearchTypes } from "./SearchTypes.data";
import OutsideAlerter from "../../utils/OutsideClickDetector";

interface IFilterPayload {
  option: FilterTypes | SearchTypes;
  optionList: FilterOptionSchema[];
  multipleSelection: boolean;
  onSelection: (
    val: FilterOptionSchema[],
    option: FilterTypes | SearchTypes
  ) => void;
  mode?: "showCount" | "showNames";
  showPlaceholder?: boolean;
  preSelected?: FilterOptionSchema[];
  direction?: "left" | "right";
  addNew?: boolean;
  onAddNew?: () => void;
  showId?: boolean;
  classname?: string;
  vertical?: "top" | "bottom";
  sort?: boolean;
}

const Filter = ({
  option,
  optionList,
  multipleSelection,
  onSelection,
  mode = "showCount",
  showPlaceholder = true,
  preSelected = [],
  direction = "right",
  addNew = false,
  onAddNew = () => {},
  showId = false,
  classname = "",
  vertical = "bottom",
  sort = true,
}: IFilterPayload) => {
  const [selected, setSelected] = useState<FilterOptionSchema[]>([]);
  const [show, setShow] = useState(false);
  const filterToggle = () => {
    setShow((show) => !show);
  };

  const filterSelected = (val: FilterOptionSchema[]) => {
    setSelected(val);
    onSelection(val, option);
    if (!multipleSelection) {
      filterToggle();
    }
  };

  const addNewHandler = () => {
    setShow(false);
    onAddNew();
  };

  useEffect(() => {
    if (
      preSelected &&
      JSON.stringify(selected) !== JSON.stringify(preSelected)
    ) {
      setSelected(preSelected);
    }
  }, [preSelected]);

  return (
    <div className={`filter-main-container`}>
      <OutsideAlerter action={() => setShow(false)}>
        <div
          className={`filter-container ${classname} `}
          onClick={filterToggle}
        >
          {showPlaceholder && selected.length === 0 && (
            <p
              className="title xetgo-font-tag bold"
              style={{ color: "#999898" }}
            >
              Select {option.name}
            </p>
          )}
          <p
            className={`title flex-row gap-8 m-0 noselect ${
              show || selected.length > 0
                ? "xetgo-font-tag bold"
                : "xetgo-font-tag"
            }`}
          >
            {selected.length > 0 && mode === "showCount" && (
              <span className="flex-row align-items-center justify-content-center xetgo-font-tag bold filter-count">
                {selected.length}
              </span>
            )}

            {selected.length > 0 && mode === "showNames" && (
              <span className="flex-row align-items-center justify-content-center xetgo-font-tag bold">
                {selected
                  .map((selectedOption) => {
                    return selectedOption.name;
                  })
                  .join(", ")}
              </span>
            )}
          </p>
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240131100053-chevron-down.svg"
            alt="dropdown-arrow"
            height={16}
            width={16}
          />
        </div>
        {show && (
          <FilterDropdown
            vertical={vertical}
            filter={option}
            options={optionList}
            multipleSelection={multipleSelection}
            selected={selected}
            selection={filterSelected}
            direction={direction}
            addNew={addNew}
            addNewClicked={addNewHandler}
            showId={showId}
            sort={sort}
          />
        )}
      </OutsideAlerter>
    </div>
  );
};

export default Filter;
