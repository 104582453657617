import React, { useRef, useState } from "react";
import "./PurchaseRequestProduct.scss";
import {
  PurchaseOrderProduct,
  PurchaseOrderProductDeliverySchedule,
} from "../../models/PurchaseOrder";
import { commaFormat, formatDate } from "../../utils/Helper";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";
import OutsideAlerter from "../../utils/OutsideClickDetector";
import CheckListPropertyCard from "../CheckListPropertyCard/CheckListPropertyCard";

interface PurchaseRequestProductProps {
  productDetail: PurchaseOrderProduct;
  index: number;
  po_id: number;
}

const PurchaseRequestProduct: React.FC<PurchaseRequestProductProps> = ({
  productDetail,
  index,
  po_id,
}) => {
  const [product, setProduct] = useState<PurchaseOrderProduct>(productDetail);
  const [expand, setExpand] = useState(false);
  const [deliveryOpen, setDeliveryOpen] = useState(-1);
  const [newDeliveryOpen, setNewDeliveryOpen] = useState(false);
  const quantityRef = useRef<HTMLInputElement>(null);
  const [newDeliveryDate, setNewDeliveryDate] = useState<string>("");
  const [checkListOpen, setCheckListOpen] = useState(-1);

  const addDeliverySchedule = async () => {
    if (newDeliveryDate && quantityRef.current) {
      const { data } = await ApiService().client.post(
        URL.PURCHASE.ADD_DELIVERY_SCHEDULE(po_id),
        {
          prp_id: product.procurement_request_product_id,
          quantity: quantityRef.current.value,
          expected_date: newDeliveryDate,
        }
      );
      setProduct((val) => {
        val.delivery_schedules.push(data.schedule);
        return val;
      });
      quantityRef.current.value = "";
      setNewDeliveryDate("");
    }
  };

  const clearNewDelivery = () => {
    if (quantityRef.current) {
      quantityRef.current.value = "";
    }
    setNewDeliveryDate("");
  };

  const updateDeliverySchedule = async (
    id: number,
    key: string,
    value: any
  ) => {
    const { data } = await ApiService().client.put(
      URL.PURCHASE.EDIT_DELIVERY_SCHEDULE(po_id, id),
      {
        [key]: value,
      }
    );
    setProduct((val) => {
      const schedules = val.delivery_schedules.map((schedule) => {
        if (schedule.id === id) {
          return data.schedule;
        }
        return schedule;
      });
      return { ...val, delivery_schedules: schedules };
    });
  };

  const deleteDeliverySchedule = async (id: number) => {
    await ApiService().client.delete(
      URL.PURCHASE.EDIT_DELIVERY_SCHEDULE(po_id, id)
    );
    setProduct((val) => {
      const schedules = val.delivery_schedules.filter(
        (schedule) => schedule.id !== id
      );
      return { ...val, delivery_schedules: schedules };
    });
  };

  const handleExpansion = () => {
    setExpand((val) => !val);
  };

  return (
    <div
      onClick={handleExpansion}
      key={index}
      className="product-container xetgo-font-tag cursor-pointer"
    >
      <p className="index py-12 px-16">{index + 1}</p>
      <div className="product-detail-middle-container">
        <div className="product-detail-upper-container xetgo-font-tag">
          <p className=" py-12 px-16 flex-row gap-4 flex-wrap">
            <a
              href={`/skus/${product.product_id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {product.product_name}
            </a>
            <span className="ppu-pill py-2 px-6">₹ {product.price}</span>
          </p>
          <p className="ppu py-12 px-16">₹ {product.price}</p>
          <p className="py-12 px-16">
            {product.quantity} {product.unit}
          </p>
          <p className=" py-12 px-16">
            ₹ {commaFormat((product.price * product.quantity).toString())}
          </p>
          <div className="py-12 px-16 product-avatar">
            <img
              height={32}
              width={32}
              src={product.image}
              alt="product-image"
            />
          </div>
        </div>
        {expand && (
          <div
            onClick={(e) => e.stopPropagation()}
            className="expanded-main-container"
          >
            <div className="expanded-upper-main-container py-12 px-16 flex-row gap-12">
              <div className="delivery-schedule-container flex-column gap-4">
                {product.delivery_schedules.map(
                  (
                    delivery: PurchaseOrderProductDeliverySchedule,
                    deliveryIndex: number
                  ) => {
                    return (
                      <div
                        className={`po-detail-delivery-detail-container  ${
                          delivery.pod == null && "delivery-completed"
                        }`}
                        key={delivery.id}
                      >
                        <div className="flex-row price-box-container justify-content-space-between xetgo-font-tag px-12 py-8">
                          <div
                            style={{ color: "#484848" }}
                            className="xetgo-font-tag delivery-input"
                            contentEditable={true}
                            onKeyDown={(
                              e: React.KeyboardEvent<HTMLDivElement>
                            ) => {
                              if (e.key === "Enter" && !e.shiftKey) {
                                e.preventDefault();
                                updateDeliverySchedule(
                                  delivery.id,
                                  "delivered_quantity",
                                  e.currentTarget.textContent
                                );
                              }
                            }}
                            dangerouslySetInnerHTML={{
                              __html: delivery.delivered_quantity?.toString(),
                            }}
                          ></div>
                          <p
                            className="xetgo-font-tag"
                            style={{ color: "#939393" }}
                          >
                            {product.unit}
                          </p>
                        </div>
                        <div className="flex-row price-box-container justify-content-space-between xetgo-font-tag px-12 py-8">
                          <div
                            style={{ color: "#484848" }}
                            className="flex-row align-items-center gap-12 justify-content-space-between full-width"
                          >
                            <p className="xetgo-font-tag">
                              {formatDate(delivery.expected_delivery_date)}
                            </p>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                value={dayjs(delivery.expected_delivery_date)}
                                format={"DD-MM-YYYY"}
                                views={["year", "month", "day"]}
                                onChange={(event) => {
                                  setDeliveryOpen(-1);
                                  updateDeliverySchedule(
                                    delivery.id,
                                    "expected_delivery_date",
                                    event?.toDate().toISOString()
                                  );
                                }}
                                open={deliveryOpen === delivery.id}
                                className="delivery-date-selector"
                              />
                            </LocalizationProvider>
                            <img
                              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240523085546-calendar-days.svg"
                              onClick={() => setDeliveryOpen(delivery.id)}
                              className="cursor-pointer"
                              height={16}
                              width={16}
                            />
                          </div>
                        </div>
                        <div
                          className="flex-row price-box-container justify-content-space-between xetgo-font-tag px-12 py-8 gap-4 align-items-center"
                          style={{ background: "#FFFFFF" }}
                        >
                          <input
                            className="xetgo-font-tag delivery-input price-box-delivery-location"
                            placeholder="Tracking Link"
                            defaultValue={delivery.track_link}
                            onKeyDown={(
                              e: React.KeyboardEvent<HTMLInputElement>
                            ) => {
                              if (e.key === "Enter" && !e.shiftKey) {
                                e.preventDefault();
                                updateDeliverySchedule(
                                  delivery.id,
                                  "track_link",
                                  e.currentTarget.value
                                );
                              }
                            }}
                          />
                          <a
                            href={delivery.track_link}
                            target="_blank_page"
                            style={{ height: "16px" }}
                          >
                            <img
                              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231228215913-location-outline.svg"
                              alt="location-icon"
                            />
                          </a>
                        </div>

                        {delivery.pod == null && (
                          <div className="info-btn-container flex-row align-items-center justify-content-center ">
                            <img
                              onClick={() => setCheckListOpen(deliveryIndex)}
                              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240627134615-info+%281%29.svg"
                              alt="info-icon"
                              height={16}
                              width={16}
                            />
                            {checkListOpen === deliveryIndex && (
                              <CheckListPropertyCard
                                scheduleId={delivery.id}
                                handleClose={() => setCheckListOpen(-1)}
                                quoteProductId={product.quote_product_id}
                              />
                            )}
                          </div>
                        )}
                        {delivery?.pod ? (
                          <a
                            href={delivery.pod}
                            target="_blank_page"
                            className="pod-file flex-row justify-content-space-between px-12 py-8 gap-4"
                          >
                            <p className="xetgo-font-taf">
                              POD: {formatDate(delivery.actual_delivery_date)}
                            </p>
                            <img
                              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240523092025-arrow-right+%284%29.svg"
                              alt="pod-file"
                              width={16}
                              height={16}
                              className="cursor-pointer"
                            />
                          </a>
                        ) : (
                          <div
                            className="py-8 px-10 cursor-pointer flex-row align-items-center justify-content-center border-box"
                            style={{
                              background: "#EC5151",
                              width: "36px",
                              marginLeft: "12px",
                            }}
                            onClick={() => deleteDeliverySchedule(delivery.id)}
                          >
                            <img
                              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240523124141-trash-2.svg"
                              alt="clear"
                              height={16}
                              width={16}
                            />
                          </div>
                        )}
                        {delivery.pod && (
                          <div className="info-btn-container flex-row align-items-center justify-content-center ">
                            <img
                              onClick={() => setCheckListOpen(deliveryIndex)}
                              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240627134615-info+%281%29.svg"
                              alt="info-icon"
                              height={16}
                              width={16}
                            />
                            {checkListOpen === deliveryIndex && (
                              <CheckListPropertyCard
                                scheduleId={delivery.id}
                                handleClose={() => setCheckListOpen(-1)}
                                quoteProductId={product.quote_product_id}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    );
                  }
                )}
                <div className="po-detail-new-delivery-detail-container">
                  <div className="flex-row price-box-container justify-content-space-between xetgo-font-tag px-12 py-8">
                    <input
                      style={{ color: "#484848" }}
                      className="xetgo-font-tag delivery-input"
                      contentEditable={true}
                      ref={quantityRef}
                      placeholder="00"
                    />
                    <p className="xetgo-font-tag" style={{ color: "#939393" }}>
                      {product.unit}
                    </p>
                  </div>
                  <div className="flex-row price-box-container justify-content-space-between xetgo-font-tag px-12 py-8">
                    <div
                      style={{ color: "#484848" }}
                      className="flex-row align-items-center gap-12 justify-content-space-between full-width"
                    >
                      <p className="xetgo-font-tag">
                        {newDeliveryDate
                          ? formatDate(newDeliveryDate)
                          : "Est. Delivery"}
                      </p>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={dayjs(newDeliveryDate)}
                          format={"DD-MM-YYYY"}
                          views={["year", "month", "day"]}
                          onChange={(event) => {
                            if (event?.toDate()) {
                              setNewDeliveryDate(event.toDate().toISOString());
                              setNewDeliveryOpen(false);
                            }
                          }}
                          open={newDeliveryOpen}
                          className="delivery-date-selector"
                        />
                      </LocalizationProvider>
                      <img
                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240523085546-calendar-days.svg"
                        onClick={() => setNewDeliveryOpen((val) => !val)}
                        className="cursor-pointer"
                        height={16}
                        width={16}
                      />
                    </div>
                  </div>
                  <div className="delivery-btn-container flex-row gap-4">
                    <div
                      className="py-8 px-10 cursor-pointer flex-row align-items-center justify-content-center cursor-pointer"
                      style={{ background: "#4B7F19" }}
                      onClick={addDeliverySchedule}
                    >
                      <img
                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240523124141-bookmark-plus.svg"
                        alt="save"
                        height={16}
                        width={16}
                      />
                    </div>
                    <div
                      className="p-8 cursor-pointer flex-row align-items-center justify-content-center"
                      style={{
                        background: "#F2F2F2",
                      }}
                      onClick={clearNewDelivery}
                    >
                      <img
                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240523172707-broom+1.svg"
                        alt="clear"
                        height={16}
                        width={16}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {product.files.length > 0 && (
              <div className="expanded-lower-file-container flex-row gap-8 py-12 px-16">
                <img
                  height={16}
                  width={16}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240516131144-folder+1.svg"
                  alt="files-icon"
                />
                {product.files.map((file: any, index: number) => {
                  return (
                    <a
                      className="file-link"
                      key={index}
                      href={file.url}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {file.name}
                    </a>
                  );
                })}
              </div>
            )}
          </div>
        )}
        <p className="product-description py-12 px-16">{product.description}</p>
      </div>
    </div>
  );
};
export default PurchaseRequestProduct;
