import React, { useState } from "react";
import "./QCDetaiQualityCheckCard.scss";
import XetToggle from "../xet-toggle/XetToggle";

interface QualityCheckSchema {
  key: string;
  observed_val: string;
  sku_quality_check_id: number;
  unit: string;
  standard_val: string;
  vendor_val: string;
  approved: boolean;
  delivered_quality_check_id: number;
}
interface QualityCheckProps {
  QualityCheck: QualityCheckSchema;
}

const QCDetailQualityCheckCard: React.FC<QualityCheckProps> = ({
  QualityCheck,
}) => {
  const [qualityCheck, setQualityCheck] =
    useState<QualityCheckSchema>(QualityCheck);

  return (
    <div className="quality-check-grid-container qc-detail">
      <div className="qc-check-left-grid xetgo-font-tag">
        <p className="p-12 right-border">{qualityCheck.key}</p>
        <p className="p-12 right-border">{qualityCheck.standard_val}</p>
        <p className="p-12 right-border">{qualityCheck.vendor_val}</p>
      </div>
      <div className="observation-main-container flex-row ">
        <p className="quality-check-observe-value-input xetgo-font-tag full-width p-12 border-box flex-1">
          {qualityCheck.observed_val}
        </p>
        <div className="flex-row align-items-center justify-content-flex-end py-10 px-16">
          <XetToggle
            onToggle={(value: boolean) => {}}
            toggleValue={qualityCheck.approved}
            backgroundColor="#f3f4f6"
            disableColor="#FF5252"
            disable={true}
            enableColor="#00C414"
          />
        </div>
      </div>
    </div>
  );
};

export default QCDetailQualityCheckCard;
