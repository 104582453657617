import {
  User,
  SignupUserData,
  UserDetailsUpdatedResponseSchema,
} from "../../models/User";
import {
  AddVendorAddressFailureAction,
  AddVendorAddressPayloadSchema,
  AddVendorAddressStartAction,
  AddVendorAddressSuccessAction,
  FetchVendorDetailsFailureAction,
  FetchVendorDetailsStartAction,
  FetchVendorDetailsSuccessAction,
  LogOutUserAction,
  NewCompanyAddressFailureAction,
  NewCompanyAddressPayloadSchema,
  NewCompanyAddressStartAction,
  NewCompanyAddressSuccessAction,
  SignInUserAction,
  SignInUserFailureAction,
  SignInUserSuccessAction,
  SignupUserAction,
  SignupUserFailureAction,
  SignupUserSuccessAction,
  ToggleNewNotificationAction,
  UpdateUserDetailsAction,
  UpdateUserDetailsFailureAction,
  UpdateUserDetailsSuccessAction,
  UpdateVendorProfileInfoFailureAction,
  UpdateVendorProfileInfoStartAction,
  UpdateVendorProfileInfoSuccessAction,
  UpdateVendorProfileResponse,
  UserActions,
  UserAddress,
  VendorDetails,
} from "./userTypes";

export const signupUser = (data: SignupUserData): SignupUserAction => ({
  type: UserActions.SIGNUP_USER,
  payload: data,
});

export const signupUserSuccess = (user: User): SignupUserSuccessAction => ({
  type: UserActions.SIGNUP_USER_SUCCESS,
  payload: user,
});

export const signupUserFailure = (error: string): SignupUserFailureAction => ({
  type: UserActions.SIGNUP_USER_FAILURE,
  error,
});

export const signInUser = (data: {
  email?: string;
  password?: string;
  jwt_token?: string;
}): SignInUserAction => ({
  type: UserActions.SIGNIN_USER,
  payload: data,
});

export const signInUserSuccess = (user: User): SignInUserSuccessAction => ({
  type: UserActions.SIGNIN_USER_SUCCESS,
  payload: user,
});

export const signInUserFailure = (error: string): SignInUserFailureAction => ({
  type: UserActions.SIGNIN_USER_FAILURE,
  error,
});

export const updateUserDetails = (data: FormData): UpdateUserDetailsAction => ({
  type: UserActions.UPDATE_USER_DETAILS,
  payload: data,
});

export const updateUserDetailsSuccess = (
  user: UserDetailsUpdatedResponseSchema,
): UpdateUserDetailsSuccessAction => ({
  type: UserActions.UPDATE_USER_DETAILS_SUCCESS,
  payload: user,
});

export const updateUserDetailsFailure = (
  error: string,
): UpdateUserDetailsFailureAction => ({
  type: UserActions.UPDATE_USER_DETAILS_FAILURE,
  error,
});

export const logOutUser = (): LogOutUserAction => ({
  type: UserActions.LOGOUT_USER,
});

export const newCompanyAddressStart = (
  payload: NewCompanyAddressPayloadSchema,
): NewCompanyAddressStartAction => ({
  type: UserActions.NEW_COMPANY_ADDRESS_START,
  payload,
});

export const newCompanyAddressSuccess = (
  response: UserAddress,
): NewCompanyAddressSuccessAction => ({
  type: UserActions.NEW_COMPANY_ADDRESS_SUCCESS,
  payload: response,
});

export const newCompanyAddressFailure = (
  error: string,
): NewCompanyAddressFailureAction => ({
  type: UserActions.NEW_COMPANY_ADDRESS_FAILURE,
  error,
});

export const toggleNewNotification = (): ToggleNewNotificationAction => ({
  type: UserActions.TOGGLE_NEW_NOTIFICATION,
});

export const updateVendorProfileInfoStart = (
  payload: FormData,
): UpdateVendorProfileInfoStartAction => ({
  type: UserActions.UPDATE_VENDOR_PROFILE_INFO_START,
  payload,
});

export const updateVendorProfileInfoSuccess = (
  response: UpdateVendorProfileResponse,
): UpdateVendorProfileInfoSuccessAction => ({
  type: UserActions.UPDATE_VENDOR_PROFILE_INFO_SUCCESS,
  payload: response,
});

export const updateVendorProfileInfoFailure = (
  error: string,
): UpdateVendorProfileInfoFailureAction => ({
  type: UserActions.UPDATE_VENDOR_PROFILE_INFO_FAILURE,
  error,
});

export const addVendorAddressStart = (
  payload: AddVendorAddressPayloadSchema,
): AddVendorAddressStartAction => ({
  type: UserActions.ADD_VENDOR_ADDRESS_START,
  payload,
});

export const addVendorAddressSuccess = (
  response: AddVendorAddressPayloadSchema,
): AddVendorAddressSuccessAction => ({
  type: UserActions.ADD_VENDOR_ADDRESS_SUCCESS,
  payload: response,
});

export const addVendorAddressFailure = (
  error: string,
): AddVendorAddressFailureAction => ({
  type: UserActions.ADD_VENDOR_ADDRESS_FAILURE,
  error,
});

export const fetchVendorDetailsStart = (): FetchVendorDetailsStartAction => ({
  type: UserActions.FETCH_VENDOR_DETAILS_START,
});

export const fetchVendorDetailsSuccess = (
  response: VendorDetails,
): FetchVendorDetailsSuccessAction => ({
  type: UserActions.FETCH_VENDOR_DETAILS_SUCCESS,
  payload: response,
});

export const fetchVendorDetailsFailure = (
  error: string,
): FetchVendorDetailsFailureAction => ({
  type: UserActions.FETCH_VENDOR_DETAILS_FAILURE,
  error,
});
