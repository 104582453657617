import React, { ChangeEvent, useEffect, useState } from "react";
import "./ProfileInfoEditPopup.scss";
import { useSelector } from "react-redux";
import { userDetails } from "../../redux/user/userSelector";
import { useDispatch } from "react-redux";
import { updateVendorProfileInfoStart } from "../../redux/user/userAction";
import { MEASUREMENT_UNITS } from "../../constants/Measurement.data";
import { FilterOptionSchema } from "../Filter/filter.data";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

interface ProfileInfoEditPopupProps {
  onCancel: () => void;
}

const ProfileInfoEditPopup: React.FC<ProfileInfoEditPopupProps> = ({
  onCancel,
}) => {
  const user = useSelector(userDetails);
  const dispatch = useDispatch();
  const [selectedGstFile, setSelectedGstFile] = useState<File | null>(null);
  const [selectedPanFile, setSelectedPanFile] = useState<File | null>(null);
  const [selectedTradeLicenseFile, setSelectedTradeLicenseFile] =
    useState<File | null>(null);

  const [profileDetails, setProfileDetails] = useState({
    vendor_name: user.company_details?.name || "",
    owner_name: user.company_details?.owner_name || "",
    poc_name: user.name || "",
    phone_number: user.phone_number || "",
    gst: user.company_details?.gst_number || "",
    pan: user.company_details?.pan_number || "",
    employee_count: user.company_details?.employee_count || "",
    capacity: user.company_details?.capacity || "",
    capacity_unit: user.company_details?.capacity_unit || MEASUREMENT_UNITS[0],
    trade_license_number: user.company_details?.trade_license_number || "",
    tl_expiry_date: user.company_details?.tl_expiry_date || "",
    tl_issue_date: user.company_details?.tl_issue_date || "",
  });

  const editField = (key: string, value: string) => {
    setProfileDetails((val) => {
      return { ...val, [key]: value };
    });
  };

  const submitProfileEdit = () => {
    const formData = new FormData();
    formData.set("vendor_name", profileDetails.vendor_name);
    formData.set("poc_name", profileDetails.poc_name);
    formData.set("phone_number", profileDetails.phone_number);
    formData.set("gst", profileDetails.gst);
    formData.set("tl_number", profileDetails.trade_license_number);
    formData.set("tl_issue_date", profileDetails.tl_issue_date);
    formData.set("tl_expiry_date", profileDetails.tl_expiry_date);
    formData.set("pan", profileDetails.pan);
    formData.set("capacity", profileDetails.capacity);
    formData.set("capacity_unit", profileDetails.capacity_unit);
    formData.set("owner_name", profileDetails.owner_name);
    formData.set("employee_count", profileDetails.employee_count.toString());

    if (selectedGstFile) {
      formData.append("gstFile", selectedGstFile);
      formData.append("gstFileName", selectedGstFile.name);
    }
    if (selectedPanFile) {
      formData.append("panFile", selectedPanFile);
      formData.append("panFileName", selectedPanFile.name);
    }
    if (selectedTradeLicenseFile) {
      formData.append("tradeFile", selectedTradeLicenseFile);
      formData.append("tradeFileName", selectedTradeLicenseFile.name);
    }

    dispatch(updateVendorProfileInfoStart(formData));
    onCancel();
  };

  const handleGSTFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedGstFile(event.target.files[0]);
    }
  };
  const handlePanFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedPanFile(event.target.files[0]);
    }
  };
  const handleTradeLicenseFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedTradeLicenseFile(event.target.files[0]);
    }
  };

  const handleDueDateChange = async (
    key: string,
    dueDate: string | undefined
  ) => {
    if (dueDate) {
      editField(key, dueDate);
    }
  };

  return (
    <div className="profile-info-edit-container">
      <div className="flex-row justify-content-space-between p-16 profile-edit-header-container">
        <p className="xetgo-font-button bold">Profile</p>
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240508063250-x.svg"
          alt="close"
          className="cursor-pointer"
          width={16}
          height={16}
          onClick={onCancel}
        />
      </div>
      <div className="info-edit-content-container py-12 px-16 flex-column gap-12 ">
        <div className="edit-field-container flex-column gap-8">
          <p className="xetgo-font-tag bold" style={{ color: "#999898" }}>
            Organization Name *
          </p>
          <input
            type="text"
            className="edit-field-value px-12 py-8 xetgo-font-tag "
            value={profileDetails.vendor_name}
            onChange={(e) => editField("vendor_name", e.target.value)}
          />
        </div>
        <div className="flex-row gap-12 edit-field-row">
          <div className="edit-field-container flex-column gap-8 flex-1">
            <p className="xetgo-font-tag bold" style={{ color: "#999898" }}>
              Owner Name
            </p>
            <input
              type="text"
              className="edit-field-value px-12 py-8 xetgo-font-tag "
              value={profileDetails.owner_name}
              placeholder="company owner name ..."
              onChange={(e) => editField("owner_name", e.target.value)}
            />
          </div>
          <div className="edit-field-container flex-column gap-8 flex-1 xetgo-font-tag ">
            <p className="xetgo-font-tag bold" style={{ color: "#999898" }}>
              Capacity
            </p>
            <input
              type="text"
              className="edit-field-value capacity-input px-12 py-8 xetgo-font-tag "
              value={profileDetails.capacity}
              onChange={(e) => editField("capacity", e.target.value)}
            />
            <div className="capacity-pipe-container flex-row gap-8">
              <span className="capacity-pipe xetgo-font-tag bold">|</span>
              <select
                value={profileDetails.capacity_unit}
                onChange={(e) => editField("capacity_unit", e.target.value)}
                className="measurement-box xetgo-font-tag bold"
              >
                {MEASUREMENT_UNITS.map((unit) => (
                  <option style={{ color: "black" }} key={unit} value={unit}>
                    {unit}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="flex-row gap-12 edit-field-row">
          <div className="edit-field-container flex-column gap-8 flex-1">
            <p className="xetgo-font-tag bold" style={{ color: "#999898" }}>
              POC Name
            </p>
            <input
              type="text"
              className="edit-field-value px-12 py-8 xetgo-font-tag "
              value={profileDetails.poc_name}
              onChange={(e) => editField("poc_name", e.target.value)}
            />
          </div>
          <div className="edit-field-container flex-column gap-8 flex-1">
            <p className="xetgo-font-tag bold" style={{ color: "#999898" }}>
              Phone Number
            </p>
            <input
              type="text"
              className="edit-field-value px-12 py-8 xetgo-font-tag "
              value={profileDetails.phone_number}
              onChange={(e) => editField("phone_number", e.target.value)}
            />
          </div>
        </div>
        <div className="flex-row gap-12 edit-field-row">
          <div className="edit-field-container flex-column gap-8 flex-1">
            <p className="xetgo-font-tag bold" style={{ color: "#999898" }}>
              GSTIN Number
            </p>
            <input
              type="text"
              className="edit-field-value px-12 py-8 xetgo-font-tag "
              value={profileDetails.gst}
              onChange={(e) => editField("gst", e.target.value)}
            />
          </div>
          <div className="edit-field-container flex-column gap-8 flex-1">
            <p className="xetgo-font-tag bold" style={{ color: "#999898" }}>
              GSTIN File
            </p>
            <input
              onChange={(event) => {
                handleGSTFileChange(event);
              }}
              id="gst-fileInput"
              name="file"
              type="File"
              style={{
                position: "absolute",
                top: "12px",
                right: "12px",
                display: "none",
              }}
              multiple={false}
            />
            <label
              htmlFor="gst-fileInput"
              className="edit-field-value file-label px-12 py-8 flex-row align-items-center cursor-pointer"
            >
              <p className="xetgo-font-tag ">
                {selectedGstFile
                  ? selectedGstFile.name
                  : user.company_details?.company_attachments?.gst?.url
                  ? user.company_details.company_attachments.gst.name
                  : "Upload GSTIN File"}
                <img
                  className="upload-icon"
                  height={16}
                  width={16}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240714075812-upload+%286%29.svg"
                  alt="upload-icon"
                />
              </p>
            </label>
          </div>
        </div>

        <div className="flex-row gap-12 edit-field-row">
          <div className="edit-field-container flex-column gap-8 flex-1">
            <p className="xetgo-font-tag bold" style={{ color: "#999898" }}>
              PAN Number
            </p>
            <input
              type="text"
              className="edit-field-value px-12 py-8 xetgo-font-tag "
              value={profileDetails.pan}
              onChange={(e) => editField("pan", e.target.value)}
              placeholder="pan number..."
            />
          </div>
          <div className="edit-field-container flex-column gap-8 flex-1">
            <p className="xetgo-font-tag bold" style={{ color: "#999898" }}>
              PAN File
            </p>
            <input
              onChange={(event) => {
                handlePanFileChange(event);
              }}
              id="pan-fileInput"
              name="file"
              type="File"
              style={{
                position: "absolute",
                top: "12px",
                right: "12px",
                display: "none",
              }}
              multiple={false}
            />
            <label
              htmlFor="pan-fileInput"
              className="edit-field-value file-label px-12 py-8 flex-row align-items-center cursor-pointer"
            >
              <p className="xetgo-font-tag ">
                {selectedPanFile
                  ? selectedPanFile.name
                  : user.company_details?.company_attachments?.pan?.url
                  ? user.company_details.company_attachments.pan.name
                  : "Upload Pan File"}
                <img
                  className="upload-icon"
                  height={16}
                  width={16}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240714075812-upload+%286%29.svg"
                  alt="upload-icon"
                />
              </p>
            </label>
          </div>
        </div>

        <div className="flex-row gap-12 edit-field-row">
          <div className="edit-field-container flex-column gap-8 flex-1">
            <p className="xetgo-font-tag bold" style={{ color: "#999898" }}>
              Trade Licence Number
            </p>
            <input
              type="text"
              className="edit-field-value px-12 py-8 xetgo-font-tag "
              value={profileDetails.trade_license_number}
              onChange={(e) =>
                editField("trade_license_number", e.target.value)
              }
              placeholder="trade license number..."
            />
          </div>
          <div className="edit-field-container flex-column gap-8 flex-1">
            <p className="xetgo-font-tag bold" style={{ color: "#999898" }}>
              Issue Date
            </p>
            <div className="tl-issue-date-value xetgo-font-tag">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="edit-field-value"
                  value={dayjs(
                    user.company_details?.tl_issue_date ||
                      "".split("-").reverse().join("-")
                  )}
                  format={"DD-MM-YYYY"}
                  views={["year", "month", "day"]}
                  onChange={(event) =>
                    handleDueDateChange(
                      "tl_issue_date",
                      event?.toDate().toISOString()
                    )
                  }
                />
              </LocalizationProvider>
            </div>
          </div>

          <div className="edit-field-container flex-column gap-8 flex-1">
            <p className="xetgo-font-tag bold" style={{ color: "#999898" }}>
              Expiry Date
            </p>
            <div className="tl-issue-date-value xetgo-font-tag">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="edit-field-value"
                  value={dayjs(
                    user.company_details?.tl_expiry_date ||
                      "".split("-").reverse().join("-")
                  )}
                  disablePast
                  format={"DD-MM-YYYY"}
                  views={["year", "month", "day"]}
                  onChange={(event) =>
                    handleDueDateChange(
                      "tl_expiry_date",
                      event?.toDate().toISOString()
                    )
                  }
                />
              </LocalizationProvider>
            </div>
          </div>

          <div className="edit-field-container flex-column gap-8 flex-1">
            <p className="xetgo-font-tag bold" style={{ color: "#999898" }}>
              Trade License File
            </p>
            <input
              onChange={(event) => {
                handleTradeLicenseFileChange(event);
              }}
              id="tradeLicense-fileInput"
              name="file"
              type="File"
              style={{
                position: "absolute",
                top: "12px",
                right: "12px",
                display: "none",
              }}
              multiple={false}
            />
            <label
              htmlFor="tradeLicense-fileInput"
              className="edit-field-value file-label trade-file-label px-12 py-8 flex-row align-items-center cursor-pointer"
            >
              <p className="xetgo-font-tag label-text">
                {selectedTradeLicenseFile
                  ? selectedTradeLicenseFile.name
                  : user.company_details?.company_attachments?.trade_license
                      ?.url
                  ? user.company_details.company_attachments.trade_license.name
                  : "Upload License File"}
                <img
                  className="upload-icon"
                  height={16}
                  width={16}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240714075812-upload+%286%29.svg"
                  alt="upload-icon"
                />
              </p>
            </label>
          </div>
        </div>

        <div className="edit-field-container flex-column gap-8">
          <p className="xetgo-font-tag bold" style={{ color: "#999898" }}>
            Number of Employees *
          </p>
          <input
            type="number"
            className="edit-field-value px-12 py-8 xetgo-font-tag "
            value={profileDetails.employee_count || ""}
            placeholder="Employee count..."
            onChange={(e) => editField("employee_count", e.target.value)}
          />
        </div>
      </div>
      <div className="info-edit-button-container flex-row justify-content-flex-end p-16">
        <div
          className="cancel-btn py-8 px-16 xetgo-font-tag cursor-pointer"
          style={{ color: "#5151EC", borderRadius: "8px" }}
          onClick={onCancel}
        >
          Cancel
        </div>
        <div
          className="save-btn py-8 px-16 xetgo-font-tag cursor-pointer"
          style={{
            color: "#FFFFFF",
            backgroundColor: "#5151EC",
            borderRadius: "8px",
          }}
          onClick={submitProfileEdit}
        >
          Save
        </div>
      </div>
    </div>
  );
};
export default ProfileInfoEditPopup;
