import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectAllProducts,
  selectVendorProducts,
  userDetails,
} from "../../redux/user/userSelector";
import { useDispatch } from "react-redux";
import { updateVendorProfileInfoStart } from "../../redux/user/userAction";
import Filter from "../Filter/Filter";
import { FilterOptionSchema } from "../Filter/filter.data";
import "./ProductTaggingPopup.scss";

interface ProductTaggingPopupProps {
  onCancel: () => void;
}

const ProductTaggingPopup: React.FC<ProductTaggingPopupProps> = ({
  onCancel,
}) => {
  const user = useSelector(userDetails);
  const dispatch = useDispatch();
  const allProducts = useSelector(selectAllProducts);
  const selectedItems = useSelector(selectVendorProducts);
  const [taggedProducts, setTaggedProducts] = useState<FilterOptionSchema[]>(
    []
  );
  const [filteredList, setFilteredList] = useState<FilterOptionSchema[]>([]);

  useEffect(() => {
    const preItem = selectedItems.map((val) => val.id);
    setFilteredList(
      allProducts.filter((val) => preItem.indexOf(val.id) === -1)
    );
  }, []);

  const submitProfileEdit = () => {
    if (user?.id) {
      const formData = new FormData();
      formData.set(
        "product_tags",
        JSON.stringify(taggedProducts.map((row) => row.id))
      );
      dispatch(updateVendorProfileInfoStart(formData));
    }
    onCancel();
  };

  return (
    <div className="profile-product-tag-container">
      <div className="flex-row justify-content-space-between p-16 profile-product-tag-header-container">
        <p className="xetgo-font-button bold">Profile</p>
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240508063250-x.svg"
          alt="close"
          className="cursor-pointer"
          width={16}
          height={16}
          onClick={onCancel}
        />
      </div>
      <div className="product-tag-content-container py-12 px-16 flex-column gap-12">
        <div className="edit-field-container flex-column gap-8">
          <Filter
            option={{
              key: "productName",
              name: "Choose Products",
            }}
            optionList={filteredList}
            multipleSelection={true}
            onSelection={(val, _option) => {
              setTaggedProducts(val);
            }}
            mode="showNames"
            showPlaceholder={true}
            preSelected={taggedProducts}
            direction="left"
            addNew={false}
          />
        </div>
        <div className="flex-row gap-12 flex-wrap">
          {taggedProducts.map((product) => {
            return (
              <p
                className="py-4 px-8 xetgo-font-tag"
                key={product.id}
                style={{
                  color: "#AA5200",
                  background: "#FFF5EC",
                  borderRadius: "6px",
                }}
              >
                {product.name}
              </p>
            );
          })}
        </div>
      </div>
      <div className="product-tag-button-container flex-row justify-content-flex-end p-16">
        <div
          className="cancel-btn py-8 px-16 xetgo-font-tag cursor-pointer"
          style={{ color: "#5151EC", borderRadius: "8px" }}
          onClick={onCancel}
        >
          Cancel
        </div>
        <div
          className="save-btn py-8 px-16 xetgo-font-tag cursor-pointer"
          style={{
            color: "#FFFFFF",
            backgroundColor: "#5151EC",
            borderRadius: "8px",
          }}
          onClick={submitProfileEdit}
        >
          Save
        </div>
      </div>
    </div>
  );
};
export default ProductTaggingPopup;
