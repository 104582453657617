import React, { useEffect, useState } from "react";
import "./ProfileProductTagging.scss";
import XetModal from "../shared/xet-modal/XetModal";
import ProductTaggingPopup from "../ProductTaggingPopup/ProductTaggingPopup";
import { useSelector } from "react-redux";
import { selectVendorProducts } from "../../redux/user/userSelector";

const ProfileProductTagging = () => {
  const tags = useSelector(selectVendorProducts);
  const [productTagEdit, setProductTagEdit] = useState(false);

  return (
    <>
      <div className="profile-product-tagging-container">
        <div className="top-product-tagging-header flex-row justify-content-space-between px-16 py-12">
          <p className="xetgo-font-tag bold" style={{ color: "#484848" }}>
            Category
          </p>
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240506100306-pen-square+%281%29.svg"
            width={16}
            height={16}
            className="cursor-pointer"
            onClick={() => setProductTagEdit(true)}
          />
        </div>
        {tags && tags.length > 0 && (
          <div className="product-tagging-details p-16 flex-row gap-6 flex-wrap">
            {tags.map((tag, index) => {
              return (
                <p key={index} className="product-tag px-8 py-4 xetgo-font-tag">
                  {tag.name}
                </p>
              );
            })}
          </div>
        )}
      </div>
      <XetModal
        backdropClose={true}
        open={productTagEdit}
        handleClose={() => setProductTagEdit(false)}
      >
        <ProductTaggingPopup onCancel={() => setProductTagEdit(false)} />
      </XetModal>
    </>
  );
};
export default ProfileProductTagging;
