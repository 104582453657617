import React, { useEffect, useState } from "react";
import { Files } from "../../models/Quote";
import "./QuoteListProductDetailModal.scss";
import URL from "../../urls/Urls";
import ApiService from "../../services/apiService";

interface QuoteListProductDetailModalProps {
  quote_id: number;
  sku_id: number;
  name: string;
  description: string;
}

const QuoteListProductDetailModal: React.FC<
  QuoteListProductDetailModalProps
> = ({ quote_id, sku_id, name, description }) => {
  const [skuAttachments, setSkuAttachments] = useState<Files[]>([]);

  useEffect(() => {
    fetchSkuAttachments(quote_id, sku_id).then((r) => {
      return r;
    });
  }, [sku_id]);

  const fetchSkuAttachments = async (quote_id: number, sku_id: number) => {
    try {
      const { data } = await ApiService().client.get(
        URL.QUOTES.FETCH_SKU_ATTACHMENTS(quote_id, sku_id),
      );
      setSkuAttachments(data.data);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="product-detail-modal-container p-24">
      <div className="description-container">
        <p className="description-heading">{name.toUpperCase()}</p>
        <p className="px-16 py-12 xetgo-font-tag" style={{ color: "#999898" }}>
          {description}
        </p>
      </div>

      {skuAttachments.length > 0 && (
        <div>
          <p className="product-attachments-heading"> PRODUCT ATTACHMENTS </p>

          <div className="products-attachments-container flex-row gap-12 p-12">
            <img
              height={16}
              width={16}
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240516131144-folder+1.svg"
              alt="files-icon"
            />
            <div className="product-attachments flex-row gap-8">
              {skuAttachments.map((file, fileIndex) => {
                return (
                  <a
                    key={`file-index-${fileIndex}`}
                    href={file.url}
                    rel="noreferrer"
                    target="_blank"
                    className="quote-file-name xetgo-font-tag"
                  >
                    {file.name}
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuoteListProductDetailModal;
