export const QUOTE_STATUS_PROPERTY_MAP: {
  [key: string]: {
    backgroundColor: string;
    color: string;
    url?: string;
    dropdown_icon?: string;
  };
} = {
  "RFQ Generated": {
    backgroundColor: "#F3E2FF",
    color: "#6610A6",
  },
  "RFQ Review": {
    color: "#255900",
    backgroundColor: "#E6FDD7",
  },
  "RFQ Incomplete": {
    color: "#004D54",
    backgroundColor: "#DEFCFF",
  },
  "RFQ Verified": {
    color: "#68003E",
    backgroundColor: "#FFE7F5",
  },
  "Re-Quote": {
    color: "#255900",
    backgroundColor: "#E6FDD7",
  },
  "Re-Quoted": {
    color: "#255900",
    backgroundColor: "#E6FDD7",
  },
  "Offer Generated": {
    color: "#004700",
    backgroundColor: "#D2FAD2",
  },
  "Discussion & Negotiation": {
    color: "#00553F",
    backgroundColor: "#D6F5ED",
  },
  Samples: {
    color: "#8C4D00",
    backgroundColor: "#FFEDD7",
  },
  "Sample Production": {
    color: "#00414A",
    backgroundColor: "#DEF7FB",
  },
  "Sample QA": {
    color: "#4F0041",
    backgroundColor: "#FFE5FB",
  },
  "In Transit - Sample": {
    color: "#541900",
    backgroundColor: "#FFEAE1",
  },
  "Sample Decision": {
    color: "#005209",
    backgroundColor: "#E4FFE7",
  },
  "RFQ Lost": {
    color: "#8F0000",
    backgroundColor: "#FBE4E4",
  },
  Regret: {
    color: "#8F0000",
    backgroundColor: "#FBE4E4",
  },
  Nurturing: {
    color: "#8F0000",
    backgroundColor: "#FBE4E4",
  },
  "RFQ Won": {
    color: "#005703",
    backgroundColor: "#DCFFDD",
  },
  "On Hold": {
    color: "#FF0000",
    backgroundColor: "#FFE7E7",
    url: "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240502072305-clock-6.svg",
    dropdown_icon:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240507110358-chevron-down+%284%29.svg",
  },
  "In Progress": {
    color: "#FF8F29",
    backgroundColor: "#FFF3E8",
    url: "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240502072305-Progress+-+Cutom.svg",
    dropdown_icon:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240507110358-chevron-down+%283%29.svg",
  },
  Completed: {
    color: "#4CAE07",
    backgroundColor: "#E9FFD9",
    url: "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240502072305-check-circle-2.svg",
    dropdown_icon:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240507110357-chevron-down+%285%29.svg",
  },
  APPROVED: {
    color: "#076100",
    backgroundColor: "#D8FFDE",
  },
};
