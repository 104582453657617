import React from "react";
import "./CommentCard.scss";
import { getFileIcon } from "../../../utils/Helper";
import { useSelector } from "react-redux";
import { userDetails } from "../../../redux/user/userSelector";
import { CommentFileSchema } from "../../../models/Quote";

interface CommentCardProps {
  user_id: number;
  avatar: string;
  name: string;
  timestamp: string;
  description: string;
  commentFiles?: CommentFileSchema[];
}

const CommentCard: React.FC<CommentCardProps> = ({
  user_id,
  avatar,
  name,
  timestamp,
  description,
  commentFiles,
}) => {
  const user = useSelector(userDetails);
  return (
    <div className="flex-row gap-8 comment-card full-width">
      <img
        src={avatar}
        alt="avatar"
        width={20}
        height={20}
        className="border-round"
      ></img>
      <div className="flex-column gap-4 flex-1  ">
        <div className="flex-row gap-8">
          <p className="xetgo-font-tag bold user">{name}</p>
          <p
            className={`comment-tag px-8 py-4 xetgo-font-mini bolder ${
              user.id === user_id && "self-comment"
            }`}
          >
            {user.id === user_id ? "Myself" : "Client"}
          </p>
          <p className="xetgo-font-mini timestamp flex-row gap-4 align-items-center">
            <span className="dot" />
            {timestamp}
          </p>
        </div>
        <div
          className="xetgo-font-tag description"
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
        {commentFiles &&
          commentFiles.map((commentFile, index) => (
            <div
              style={{ background: "white" }}
              className="file-card flex-row p-8 justify-content-space-between border-box"
              key={index}
            >
              <div className="file-left flex-row gap-8 align-items-center">
                <img
                  src={getFileIcon(commentFile)}
                  alt="file-icon"
                  height={16}
                  width={16}
                  className="file-preview"
                />
                <span style={{ color: "#E1E1E1" }}>|</span>
                <p
                  style={{
                    color: "#4A4A4A",
                    width: "220px",
                    wordBreak: "break-all",
                  }}
                  className="xetgo-font-tag bolder file-name"
                >
                  {commentFile.name}
                </p>
              </div>
              <div className="flex-row gap-12 align-items-center">
                <a
                  href={commentFile.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240220065924-download.svg"
                    alt="download"
                    width={16}
                    height={16}
                  />
                </a>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CommentCard;
