import React, { useState } from "react";
import "./QCDetailQualityCheckTestCard.scss";
import { useParams } from "react-router-dom";
import XetToggle from "../xet-toggle/XetToggle";

interface QualityCheckTestProps {
  QualityCheckTest: {
    approved: boolean;
    description: string;
    name: string;
    remarks: string;
    sku_quality_check_test_id: number;
    test_method: string | null;
    delivered_quality_check_test_id: number;
  };
}

const QCDetailQualityCheckTestCard: React.FC<QualityCheckTestProps> = ({
  QualityCheckTest,
}) => {
  const { id } = useParams();
  const [qualityCheckTest, setQualityCheckTest] = useState(QualityCheckTest);

  const [testMethodValue, setTestMethodValue] = useState(
    qualityCheckTest.test_method || ""
  );

  return (
    <div className="flex-column qct-main-container ">
      <div className="quality-check-grid-container qc-detail">
        <div className="qc-check-test-left-grid xetgo-font-tag">
          <p className="p-12 right-border bold">{qualityCheckTest.name}</p>
          <p className="p-12 right-border description">
            {qualityCheckTest.description}
          </p>
        </div>
        <div className="observation-main-container flex-row ">
          <textarea
            disabled
            className={`quality-check-observe-value-input test-method xetgo-font-tag full-width p-12 border-box`}
            value={testMethodValue || ""}
          />

          <div className="flex-row align-items-center justify-content-flex-end py-10 px-16">
            <XetToggle
              onToggle={(value: boolean) => {}}
              toggleValue={qualityCheckTest.approved}
              backgroundColor="#f3f4f6"
              disableColor="#FF5252"
              enableColor="#00C414"
              disable={true}
            />
          </div>
        </div>
      </div>
      <div className="px-12 py-8 full-width border-box">
        <textarea
          disabled
          className="px-12 py-8 qct-remark-area full-width border-box"
          value={qualityCheckTest.remarks}
          placeholder="Remarks..."
        ></textarea>
      </div>
    </div>
  );
};

export default QCDetailQualityCheckTestCard;
