import React from "react";
import { styles } from "./QAReportStyles";
import { Page, Text, View, Document, Link, Image } from "@react-pdf/renderer";
import { UserState } from "../../redux/user/userReducer";
import { SkuQualityDetailSchema } from "../../models/SkuQualityDetail";

interface IQAReport {
  user: UserState;
  currentDate: string;
  qualityCheckDetail: SkuQualityDetailSchema;
}

const QAReport: React.FC<IQAReport> = ({
  user,
  qualityCheckDetail,
  currentDate,
}) => {
  const fileName = (
    qualityCheckDetail.client_details.client.name +
    "_" +
    qualityCheckDetail.sku_name +
    "_" +
    qualityCheckDetail.actual_delivery_date.split("T")[0] +
    ".pdf"
  ).replaceAll(" ", "_");

  return (
    <Document title={fileName}>
      <Page size="A4" style={styles.page}>
        <View fixed style={styles.borderOut}>
          <View style={styles.borderIn}></View>
        </View>
        <View style={styles.pageHeader}>
          <View style={styles.pageHeaderLeftField}>
            <Text style={styles.pageHeaderKey}>PO No.</Text>
            <Text style={styles.pageHeaderValue}>
              #{qualityCheckDetail.po_number}
            </Text>
          </View>
          <Text style={styles.pageHeaderTitle}>Quality Report</Text>
          <View style={styles.pageHeaderRightField}>
            <Text style={styles.pageHeaderKey}>Date</Text>
            <Text style={styles.pageHeaderValue}>{currentDate}</Text>
          </View>
        </View>
        <View style={styles.vendorAddressContainer}>
          <View style={styles.vendorAddressLeft}>
            <Text style={styles.vendorAddressTitle}>
              {qualityCheckDetail.client_details.client.name}
            </Text>
            <Text style={styles.vendorAddressContent}>
              {qualityCheckDetail.client_details.client_poc.name}
            </Text>
          </View>
          <View style={styles.vendorAddressRight}>
            <Text style={styles.vendorAddressTitle}>
              {qualityCheckDetail.address.city},{" "}
              {qualityCheckDetail.address.state}
            </Text>
            <Text style={styles.vendorAddressContent}>
              {qualityCheckDetail.address.address_line}
            </Text>
            <Text style={styles.vendorAddressContent}>
              {qualityCheckDetail.address.pincode}
            </Text>
          </View>
        </View>
        <View style={styles.qaStatsContainer}>
          <View style={styles.qaStatsLeft}>
            {qualityCheckDetail.procurement_request_product_delivery_status ===
              "PARTIALLY APPROVED" && (
              <View style={styles.qaStatusPAContainer}>
                <Text style={styles.qaStatusPA}>
                  {
                    qualityCheckDetail.procurement_request_product_delivery_status
                  }
                </Text>
              </View>
            )}
            {qualityCheckDetail.procurement_request_product_delivery_status ===
              "APPROVED" && (
              <View style={styles.qaStatusAContainer}>
                <Text style={styles.qaStatusA}>
                  {
                    qualityCheckDetail.procurement_request_product_delivery_status
                  }
                </Text>
              </View>
            )}
            {qualityCheckDetail.procurement_request_product_delivery_status ===
              "REJECTED" && (
              <View style={styles.qaStatusRContainer}>
                <Text style={styles.qaStatusR}>
                  {
                    qualityCheckDetail.procurement_request_product_delivery_status
                  }
                </Text>
              </View>
            )}
            <View style={styles.qaStatsLeft}>
              <View style={styles.flexRow}>
                <Text style={styles.qaProductKey}>Product: </Text>
                <Text style={styles.qaProductName}>
                  {qualityCheckDetail.sku_name}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.qaStatsRight}>
            <View style={styles.qaStatsLeft}>
              <View style={styles.flexRow}>
                <Text style={styles.qaProductKey}>Quantity: </Text>
                <Text style={styles.qaProductName}>
                  {qualityCheckDetail.delivered_quantity}{" "}
                  {qualityCheckDetail.sku_unit}
                </Text>
              </View>
            </View>
            <View style={styles.qaStatsLeft}>
              <View style={styles.flexRow}>
                <Text style={styles.qaProductKey}>Approved: </Text>
                <Text style={styles.qaProductName}>
                  {qualityCheckDetail.approved_quantity}{" "}
                  {qualityCheckDetail.sku_unit}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.parameterHeaderContainer}>
          <Text style={styles.parameterHeader}>Parameters</Text>
          <Text style={styles.valueHeader}>Standard</Text>
          <Text style={styles.valueHeader}>Vendor</Text>
          <Text style={styles.valueHeader}>Observed</Text>
          <Text style={styles.statusHeader}>Status</Text>
        </View>
        {qualityCheckDetail.sku_quality_checks.map((check) => {
          return (
            <View
              style={styles.parameterAnswerContainer}
              key={check.sku_quality_check_id}
            >
              <Text style={styles.parameterAnswer}>
                {check.key} {check.unit != null ? `(${check.unit})` : ""}
              </Text>
              <Text style={styles.valueAnswer}>{check.standard_val}</Text>
              <Text style={styles.valueAnswer}>{check.vendor_val}</Text>
              <Text style={styles.valueAnswer}>{check.observed_val}</Text>
              <View style={styles.statusAnswer}>
                <Image
                  style={styles.statusImage}
                  src={
                    check.approved
                      ? "https://xetoolbucket.s3.ap-south-1.amazonaws.com/assets%2F20240909131833-check.jpg"
                      : "https://xetoolbucket.s3.ap-south-1.amazonaws.com/assets%2F20240909131833-x.jpg"
                  }
                ></Image>
              </View>
            </View>
          );
        })}
        <View style={styles.testHeaderContainer}>
          <Text style={styles.testNameHeader}>Test</Text>
          <Text style={styles.remarkHeader}>Method</Text>
          <Text style={styles.remarkHeader}>Remark</Text>
          <Text style={styles.statusHeader}>Status</Text>
        </View>
        {qualityCheckDetail.sku_quality_check_tests.map((test) => {
          return (
            <View
              style={styles.testAnswerContainer}
              key={test.sku_quality_check_test_id}
            >
              <View style={styles.testNameAnswer}>
                <Text style={styles.testName}>{test.name}</Text>
                <View>
                  <Text style={styles.testDescription}>Description</Text>
                  <Text style={styles.testDescription}>{test.description}</Text>
                </View>
              </View>
              <Text style={styles.testMethodAnswer}>{test.test_method}</Text>
              <Text style={styles.testRemarkAnswer}>{test.remarks}</Text>
              <View style={styles.statusAnswer}>
                <Image
                  style={styles.statusImage}
                  src={
                    test.approved
                      ? "https://xetoolbucket.s3.ap-south-1.amazonaws.com/assets%2F20240909131833-check.jpg"
                      : "https://xetoolbucket.s3.ap-south-1.amazonaws.com/assets%2F20240909131833-x.jpg"
                  }
                ></Image>
              </View>
            </View>
          );
        })}
        {qualityCheckDetail.delivery_attachments.length > 0 && (
          <>
            <View style={styles.attachmentHeaderContainer}>
              <Text style={styles.attachmentHeader}>
                Delivered Batch Images
              </Text>
              <Text style={styles.attachmentHeader}>Damaged Images</Text>
            </View>
            <View style={styles.attachmentAnswerContainer}>
              <View style={[styles.attachmentList, styles.borderRight]}>
                {qualityCheckDetail.delivery_attachments
                  .filter((row) => row.status === "ACCEPTED")
                  .map((file) => {
                    return (
                      <Link
                        style={styles.attachment}
                        key={file.id}
                        src={file.url}
                      >
                        {file.url.split("-").at(-1)}
                      </Link>
                    );
                  })}
              </View>
              <View style={styles.attachmentList}>
                {qualityCheckDetail.delivery_attachments
                  .filter((row) => row.status === "REJECTED")
                  .map((file) => {
                    return (
                      <Link
                        style={styles.attachment}
                        key={file.id}
                        src={file.url}
                      >
                        {file.url.split("-").at(-1)}
                      </Link>
                    );
                  })}
              </View>
            </View>
          </>
        )}
        <View style={styles.pageFooter}>
          <Text>Thank You,</Text>
          <View>
            <Text style={styles.pageFooterName}>{user.name}</Text>
            <Text style={styles.pageFooterName}>
              {user.company_details?.name}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default QAReport;
