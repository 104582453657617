export const DELIVERY_CARD_STATUSES = {
  same_day: {
    custom_class: "same-day",
    imageUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240122081125-map-pin.svg",
    text: "",
    emptyTrackImageUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240605112219-Frame+1321314756.svg",
  },
  delayed_expected_yellow: {
    custom_class: "delayed-expected-yellow",
    imageUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240122101916-yellow-map-pin.svg",
    text: "Delayed",
    emptyTrackImageUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240606105622-due+%281%29.svg",
  },
  delayed_actual_red: {
    custom_class: "delayed-actual-red",
    imageUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240122101916-red-map-pin.svg",
    text: "Delayed",
    emptyTrackImageUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240606103855-overdue.svg",
  },
  early_actual: {
    custom_class: "early-actual",
    imageUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240123202338-dark-green-map-pin.svg",
    text: "Delivered early",
    emptyTrackImageUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240606110052-delivered+early+actual.svg",
  },
  early_expected: {
    custom_class: "early-expected",
    imageUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240123141512-green-map-pin.svg",
    text: "Delivered early ",
    emptyTrackImageUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240606105855-delivered+early+-+scheduled+%281%29.svg",
  },
};
