import React, { useState } from "react";
import "./QuoteStatusDropdown.scss";
import { QUOTE_STATUS_PROPERTY_MAP } from "../../constants/Quotes.data";

interface QuoteStatusDropdownProps {
  statuses: string[];
  updateStatus: (cat: string, value: string) => void;
}
const QuoteStatusDropdown: React.FC<QuoteStatusDropdownProps> = ({
  statuses,
  updateStatus,
}) => {
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className=" quote-status-dropdown-wrapper-container"
    >
      <div className="quote-status-dropdown-main-container p-4 flex-column gap-4">
        {statuses.map((status, index) => {
          return (
            <p
              onClick={() => updateStatus("status", status)}
              key={index}
              style={{
                color: QUOTE_STATUS_PROPERTY_MAP[status].color,
                background: QUOTE_STATUS_PROPERTY_MAP[status].backgroundColor,
              }}
              className="product-status-dropdown py-6  flex-row align-items-center justify-content-center"
            >
              {status}
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default QuoteStatusDropdown;
