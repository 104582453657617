import { put, call, takeLatest, all } from "redux-saga/effects";
import {
  signupUserSuccess,
  signupUserFailure,
  signInUserSuccess,
  signInUserFailure,
  newCompanyAddressSuccess,
  newCompanyAddressFailure,
  updateUserDetailsSuccess,
  updateUserDetailsFailure,
  updateVendorProfileInfoSuccess,
  updateVendorProfileInfoFailure,
  addVendorAddressSuccess,
  addVendorAddressFailure,
  fetchVendorDetailsSuccess,
  fetchVendorDetailsFailure,
  logOutUser,
} from "./userAction";
import { User, UserDetailsUpdatedResponseSchema } from "../../models/User";
import {
  AddVendorAddressStartAction,
  FetchVendorDetailsStartAction,
  NewCompanyAddressStartAction,
  SignInUserAction,
  SignupUserAction,
  UpdateUserDetailsAction,
  UpdateVendorProfileInfoStartAction,
  UserActions,
} from "./userTypes";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";

function* signupUserSaga(action: SignupUserAction) {
  try {
    const { data } = yield call(
      ApiService().client.post,
      URL.USERS.SIGNUP,
      action.payload,
    );
    const user: User = data.user;
    yield put(signupUserSuccess(user));
  } catch (error) {
    yield put(signupUserFailure("Error fetching user data."));
  }
}

function* watchSignupUser() {
  yield takeLatest(UserActions.SIGNUP_USER, signupUserSaga);
}

function* signInUserSaga(action: SignInUserAction) {
  try {
    const { data } = yield call(ApiService().client.get, URL.USERS.SIGNIN, {
      params: action.payload,
    });
    const user: User = data.user;
    yield put(signInUserSuccess(user));
  } catch (error) {
    yield put(logOutUser());
  }
}

function* watchSignInUser() {
  yield takeLatest(UserActions.SIGNIN_USER, signInUserSaga);
}

function* updateUserDetailsSaga(action: UpdateUserDetailsAction) {
  try {
    const { data } = yield call(
      ApiService().client.post,
      URL.USERS.UPDATE_USER_COMPANY_DETAILS,
      action.payload,
    );
    const updatedDetailsResponse: UserDetailsUpdatedResponseSchema = data.data;
    yield put(updateUserDetailsSuccess(updatedDetailsResponse));
  } catch (error) {
    yield put(updateUserDetailsFailure("Error Updating User Details"));
  }
}

function* watchUpdateUserDetails() {
  yield takeLatest(UserActions.UPDATE_USER_DETAILS, updateUserDetailsSaga);
}

function* newCompanyAddressSaga(action: NewCompanyAddressStartAction) {
  try {
    const { data } = yield call(
      ApiService().client.post,
      URL.USERS.NEW_ADDRESS,
      action.payload,
    );
    yield put(newCompanyAddressSuccess(data));
  } catch (error) {
    yield put(newCompanyAddressFailure("Error adding addresses."));
  }
}

function* watchNewCompanyAddress() {
  yield takeLatest(
    UserActions.NEW_COMPANY_ADDRESS_START,
    newCompanyAddressSaga,
  );
}

function* updateVendorProfileInfoSaga(
  action: UpdateVendorProfileInfoStartAction,
) {
  try {
    const { data } = yield call(
      ApiService().client.put,
      URL.USERS.UPDATE_VENDOR_PROFILE_INFO,
      action.payload,
    );
    yield put(updateVendorProfileInfoSuccess({ ...action.payload, ...data }));
  } catch (error) {
    yield put(updateVendorProfileInfoFailure("Error adding addresses."));
  }
}

function* watchUpdateVendorProfileInfo() {
  yield takeLatest(
    UserActions.UPDATE_VENDOR_PROFILE_INFO_START,
    updateVendorProfileInfoSaga,
  );
}

function* addVendorAddressSaga(action: AddVendorAddressStartAction) {
  try {
    const { data } = yield call(
      ApiService().client.post,
      URL.USERS.NEW_ADDRESS,
      action.payload,
    );
    yield put(
      addVendorAddressSuccess({ ...data.address, address_poc: data.poc }),
    );
  } catch (error) {
    yield put(addVendorAddressFailure("Error adding addresses."));
  }
}

function* watchAddVendorAddress() {
  yield takeLatest(UserActions.ADD_VENDOR_ADDRESS_START, addVendorAddressSaga);
}

function* fetchVendorDetailsSaga(action: FetchVendorDetailsStartAction) {
  try {
    const { data } = yield call(
      ApiService().client.get,
      URL.USERS.FETCH_VENDOR_DETAILS,
    );
    yield put(fetchVendorDetailsSuccess(data));
  } catch (error) {
    yield put(fetchVendorDetailsFailure("Error adding addresses."));
  }
}

function* watchFetchVendorDetails() {
  yield takeLatest(
    UserActions.FETCH_VENDOR_DETAILS_START,
    fetchVendorDetailsSaga,
  );
}

export default function* userSaga() {
  yield all([
    watchSignupUser(),
    watchSignInUser(),
    watchUpdateUserDetails(),
    watchNewCompanyAddress(),
    watchUpdateVendorProfileInfo(),
    watchAddVendorAddress(),
    watchFetchVendorDetails(),
  ]);
}
