import React, { useEffect, useState } from "react";
import "./Profile.scss";
import VendorStats from "../../components/VendorStats/VendorStats";
import ProfileAddress from "../../components/ProfileAddress/ProfileAddress";
import ProfileProductTagging from "../../components/ProfileProductTagging/ProfileProductTagging";
import FactoryImages from "../../components/FactoryImages/FactoryImages";
import ProfileFactoryMachines from "../../components/ProfileFactoryMachines/ProfileFactoryMachines";
import XetModal from "../../components/shared/xet-modal/XetModal";
import ProfileInfoEditPopup from "../../components/ProfileInfoEditPopup/ProfileInfoEditPopup";
import { useSelector } from "react-redux";
import {
  selectProfilePercentageCompletion,
  userDetails,
} from "../../redux/user/userSelector";
import { useDispatch } from "react-redux";
import { fetchVendorDetailsStart } from "../../redux/user/userAction";

const Profile = () => {
  const user = useSelector(userDetails);
  const dispatch = useDispatch();
  const [profileInfoEdit, setProfileInfoEdit] = useState(false);
  const profileCompletion = useSelector(selectProfilePercentageCompletion);

  useEffect(() => {
    dispatch(fetchVendorDetailsStart());
  }, []);

  return (
    <div className="main-content-container flex-column">
      <div className="breadcrumb-container px-32 py-12 flex-row gap-4 xetgo-font-tag">
        <span className="bold" style={{ color: "#AA5200" }}>
          Digifac
        </span>
        <span>/</span>
        <span> Company Profile</span>
      </div>
      <div className="vendor-detail-container p-16 flex-column gap-12">
        {profileCompletion < 100 && (
          <div className="profile-complete-container p-16 flex-column gap-8">
            <p className="xetgo-font-button bold" style={{ color: "#383838" }}>
              Complete Your Profile
            </p>
            <p className="xetgo-font-tag" style={{ color: "#8F8F8F" }}>
              By completing all the details you have a higher chance of getting
              seen by Clients.
            </p>
            <div className="progress-container flex-row gap-12 align-items-center">
              <p className="xetgo-font-tag bold" style={{ color: "#000000" }}>
                {profileCompletion} %
              </p>
              <div className="flex-1 profile-progress-background">
                <div
                  className="profile-progress"
                  style={{ width: `${profileCompletion}%` }}
                ></div>
              </div>
            </div>
          </div>
        )}
        <div className="profile-name-container p-16 flex-column gap-16">
          <div className="flex-row justify-content-space-between">
            <div className="flex-row gap-12 align-items-center">
              <img
                style={{ objectFit: "cover" }}
                src="https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/35af6a41332353.57a1ce913e889.jpg"
                width={40}
                height={40}
                alt="profile-photo"
                className="border-round"
              />
              <div
                className="flex-column gap-4 xetgo-font-tag"
                style={{ color: "#FFFFFF" }}
              >
                <p className="bolder">{user.company_details?.name}</p>
                <p>
                  {user.name},
                  <span style={{ color: "#B3B3B3", marginLeft: "4px" }}>
                    {user.phone_number}
                  </span>
                </p>
              </div>
              <div className="factory-counter flex-row gap-10 align-items-center px-12 py-6">
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240506081302-business.svg"
                  width={12}
                  height={12}
                  alt="factory"
                />
                <p className="xetgo-font-tag">
                  {user.addresses.length} Factories
                </p>
              </div>
            </div>
            <div className="gst-container py-12 px-16 xetgo-font-tag bold flex-column gap-8">
              <p>
                TRADE LICENSE No. :{user.company_details?.trade_license_number}
              </p>
              <p>GST : {user.company_details?.gst_number}</p>
              <p>PAN : {user.company_details?.pan_number}</p>
            </div>
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240506082013-pen-square.svg"
              width={16}
              height={16}
              alt="edit-icon"
              className="edit-banner-icon cursor-pointer"
              onClick={() => setProfileInfoEdit(true)}
            />
          </div>
          <div className="flex-row justify-content-space-between">
            <div className="flex-column gap-4">
              <p className="xetgo-font-tag" style={{ color: "#FFFFFF" }}>
                {` ${user.company_details?.capacity} 
                ${user.company_details?.capacity_unit}`}
              </p>
              <p className="xetgo-font-tag" style={{ color: "#FFFFFF" }}>
                {user.company_details?.employee_count} Employees
              </p>
            </div>
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240506082013-pen-square.svg"
              width={16}
              height={16}
              alt="edit"
              className="edit-banner-lower-icon cursor-pointer"
              onClick={() => setProfileInfoEdit(true)}
            />
            <p
              className="xetgo-font-tag gst-banner-lower-icon"
              style={{ color: "#FFFFFF" }}
            >
              GST : {user.company_details?.gst_number}
            </p>
          </div>
        </div>
        <VendorStats />
        <ProfileAddress />
        <ProfileProductTagging />
        <ProfileFactoryMachines />
        <FactoryImages />
        <XetModal
          backdropClose={true}
          open={profileInfoEdit}
          handleClose={() => setProfileInfoEdit(false)}
        >
          <ProfileInfoEditPopup onCancel={() => setProfileInfoEdit(false)} />
        </XetModal>
      </div>
    </div>
  );
};
export default Profile;
