import React, { useEffect, useState } from "react";
import "./SkuDetail.scss";
import { useNavigate, useParams } from "react-router-dom";
import URLS from "../../urls/Urls";
import ApiService from "../../services/apiService";
import { SkuDetailSchema } from "../../models/SkuListSchema";
import SkuDetailCard from "../../components/SkuDetailCard/SkuDetailCard";
import SkuQualityCheck from "../../components/SkuQualityCheck/SkuQualityCheck";

const SkuDetail: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(0);
  const [addNewQuality, setAddNewQuality] = useState(false);
  const [addNewQualityTest, setAddNewQualityTest] = useState(false);
  const [skuDetail, setSkuDetail] = useState<SkuDetailSchema>();

  useEffect(() => {
    getSkuDetails();
  }, []);

  const getSkuDetails = async () => {
    try {
      const { data } = await ApiService().client.get(
        URLS.SKU.GET_SKU_DETAILS(Number(id))
      );
      setSkuDetail(data);
    } catch (error) {
      console.error("error fetching Sku Detail", error);
    }
  };

  const handleSkuDetailsUpdate = (data: SkuDetailSchema) => {
    setSkuDetail(data);
  };

  return (
    <div className="sku-detail-main-page flex-column full-width">
      <div className="upper-bar">
        <p
          className="xetgo-font-tag bold px-16 py-12 flex-row gap-4 cursor-pointer"
          onClick={() => navigate("/skus")}
        >
          SKU
          <span className="slash">/</span>
          <span className="sub-title xetgo-font-tag">
            {skuDetail?.uuid} - {skuDetail?.sku_name}
          </span>
        </p>
      </div>
      <div className="sku-detail-Lower-main-container flex-column gap-12 p-16 flex-1">
        <div className="sku-details-tab-main-container flex-row justify-content-space-between xetgo-font-tag">
          <div className="all-tab-container flex-row align-items-center p-2">
            <p
              onClick={() => setActiveTab(0)}
              className={`px-12 py-6 cursor-pointer bold ${
                activeTab === 0 && "active"
              } `}
            >
              Details
            </p>
            <p
              onClick={() => setActiveTab(1)}
              className={`px-12 py-6 cursor-pointer bold ${
                activeTab === 1 && "active"
              } `}
            >
              Quality Check
            </p>
          </div>
        </div>
        <div className="flex-1 tab-details-container">
          {activeTab === 0 && skuDetail && (
            <SkuDetailCard
              sku={skuDetail}
              handleUpdate={handleSkuDetailsUpdate}
            />
          )}

          {activeTab === 1 && skuDetail && (
            <SkuQualityCheck
              addNewQuality={addNewQuality}
              skuId={skuDetail.id}
              addNewQualityTest={addNewQualityTest}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SkuDetail;
