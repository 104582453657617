import React, { useEffect, useMemo, useState } from "react";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";
import PurchaseListCard from "../../components/PurchaseListCard/PurchaseListCard";
import { PurchaseOrderCard } from "../../models/PurchaseOrder";
import "./PurchaseOrder.scss";
import { debounce } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

const PurchaseOrder: React.FC = () => {
  const [orders, setOrders] = useState<PurchaseOrderCard[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchedText, setSearchedText] = useState<string>("");
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    getPurchaseList(currentPage, searchedText);
  }, [searchedText, currentPage]);

  const getPurchaseList = async (pageNumber: number, searchText: string) => {
    try {
      const { data } = await ApiService().client.get(
        `${URL.PURCHASE.LIST}?pageNumber=${pageNumber}&search=${searchText}&perPage=20`
      );
      if (data.orders.length < 20) {
        setHasMore(false);
      }
      setOrders((orders) => {
        if (pageNumber > 1) {
          return [...orders, ...data.orders];
        } else {
          return data.orders;
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleSearchFile = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const searchText = event.target.value;
    debouncedSearch(searchText);
  };

  const debouncedSearch = useMemo(() => {
    return debounce((searchText: string) => {
      setSearchedText(searchText);
    }, 500);
  }, []);

  return (
    <div className="purchase-status-content-page full-width flex-column">
      <div className="purchase-status-upper-bar px-32 py-12 xetgo-font-tag">
        <p>
          <span className="sub-title bold">Purchase Orders</span>
        </p>
      </div>
      <div className="purchase-status-lower-main-container p-16 flex-column gap-12 flex-1 overflow-hidden ">
        <div
          className="purchase-status-upper-search-filter-container position-relative flex-row justify-content-space-between
                      align-items-center gap-12 xetgo-font-tag"
        >
          <div className="search-bar-container">
            <input
              className="py-8 px-34 search-input border-box xetgo-font-tag"
              onChange={handleSearchFile}
              placeholder="Search ..."
            />
            <img
              height={16}
              width={16}
              className="search-icon "
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240515071345-search+%281%29.svg"
              alt="search-icon"
            />
          </div>
        </div>
        <div className="purchase-status-lower-details-main-container flex-column flex-1 overflow-hidden ">
          <div className="detail-heading-container xetgo-font-tag bold">
            <p className="p-12 tab-below-hidden-field"></p>
            <div className="flex-row gap-8 align-items-center p-12 ">
              <img
                height={12}
                width={12}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240515083543-table-properties.svg"
                alt="enquiry-icon"
              />
              <p>Purchase ID</p>
            </div>
            <div className="flex-row gap-8 align-items-center p-12 tab-below-hidden-field">
              <img
                height={12}
                width={12}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240516052108-calendar.svg"
                alt="enquiry-icon"
              />
              <p>Ordered on</p>
            </div>
            <div className="flex-row gap-8 align-items-center p-12 tab-below-hidden-field">
              <img
                height={12}
                width={12}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240516052108-user.svg"
                alt="enquiry-icon"
              />
              <p>Client</p>
            </div>
            <div className="flex-row gap-8 align-items-center p-12">
              <img
                height={12}
                width={12}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240516052108-rupee+1.svg"
                alt="enquiry-icon"
              />
              <p>PO Value</p>
            </div>
            <div className="flex-row gap-8 align-items-center p-12 product-count-box">
              <img
                height={12}
                width={12}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240516052108-file-box.svg"
                alt="enquiry-icon"
              />
              <p>Products</p>
            </div>
          </div>

          <div
            className="quote-list-card-container flex-column flex-1 overflow-scroll"
            id="po-scrollableDiv"
          >
            {orders.length > 0 && (
              <InfiniteScroll
                dataLength={orders?.length || 20}
                next={() => setCurrentPage((cp) => cp + 1)}
                hasMore={hasMore}
                loader={<h4></h4>}
                className="infiniteScrollComponent"
                scrollableTarget="po-scrollableDiv"
              >
                {orders.map((order, index) => {
                  return (
                    <PurchaseListCard
                      key={index}
                      index={index + 1}
                      details={order}
                    />
                  );
                })}
              </InfiniteScroll>
            )}
            {orders.length === 0 && (
              <div className="empty-selection flex-column gap-24 align-items-center justify-content-center">
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240207115819-file-box+%281%29.svg"
                  alt="no-product"
                  width={36}
                  height={36}
                />
                <p className="empty-image-text xetgo-font-button bold">
                  Purchase Orders Not Found
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseOrder;
