import {
  BaseProduct,
  FactoryImage,
  User,
  UserCompanyDetails,
  UserDetailsUpdatedResponseSchema,
} from "../../models/User";
import {
  UpdateVendorProfileResponse,
  UserActions,
  UserActionTypes,
  UserAddress,
  VendorDetails,
} from "./userTypes";
import { JWT_TOKEN, removeCookie } from "../../services/cookieService";

export interface UserState {
  loading: boolean;
  error: string | null | undefined;
  newNotification: boolean;
  name: string | null;
  email: string | null;
  phone_number: string | null;
  avatar: string | null;
  id: number | null;
  role: string[];
  addresses: UserAddress[];
  jwtToken: string | null;
  company_id: number | null;
  company_details: UserCompanyDetails | null;
  products: BaseProduct[];
  vendor_machines: string[];
  vendor_products: number[];
  vendor_images: FactoryImage[];
  quotes: number;
  total_order: number;
  total_pos: number;
  next_delivery: string;
}

const initialState: UserState = {
  name: null,
  email: null,
  avatar: null,
  id: null,
  phone_number: null,
  role: [],
  addresses: [],
  jwtToken: null,
  newNotification: false,
  loading: false,
  error: null,
  company_id: null,
  company_details: null,
  vendor_machines: [],
  vendor_images: [],
  vendor_products: [],
  products: [],
  quotes: 0,
  total_order: 0,
  total_pos: 0,
  next_delivery: "",
};

const userReducer = (
  state = initialState,
  action: UserActionTypes
): UserState => {
  switch (action.type) {
    case UserActions.SIGNUP_USER:
      return {
        ...state,
        loading: true,
      };
    case UserActions.SIGNUP_USER_SUCCESS: {
      const payload = action.payload as User;
      if (payload) {
        return {
          ...state,
          name: payload.name,
          email: payload.email,
          phone_number: payload.phone_number,
          id: payload.id,
          role: payload.role,
          addresses: [],
          jwtToken: payload.jwtToken,
          loading: false,
          error: null,
          company_id: payload.company_id,
          company_details: payload.company_details,
        };
      }
      return { ...state, loading: true };
    }
    case UserActions.SIGNUP_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case UserActions.SIGNIN_USER:
      return {
        ...state,
        loading: true,
      };

    case UserActions.SIGNIN_USER_SUCCESS: {
      const payload = action.payload as User;
      if (payload) {
        return {
          ...state,
          name: payload.name,
          email: payload.email,
          phone_number: payload.phone_number,
          id: payload.id,
          role: payload.role,
          addresses: payload.addresses,
          jwtToken: payload.jwtToken,
          loading: false,
          error: null,
          company_id: payload.company_id,
          company_details: payload.company_details,
        };
      }
      return { ...state, loading: false };
    }

    case UserActions.SIGNIN_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case UserActions.UPDATE_USER_DETAILS:
      return {
        ...state,
        loading: true,
      };

    case UserActions.UPDATE_USER_DETAILS_SUCCESS: {
      const payload = action.payload as UserDetailsUpdatedResponseSchema;
      if (payload) {
        return {
          ...state,
          addresses: payload.new_address
            ? [...state.addresses, payload.new_address]
            : state.addresses,
          company_details: payload.company_details,
        };
      }
      return { ...state, loading: true };
    }

    case UserActions.UPDATE_USER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case UserActions.LOGOUT_USER:
      removeCookie(JWT_TOKEN);
      return {
        ...state,
        name: null,
        email: null,
        avatar: null,
        id: null,
        role: [],
        addresses: [],
        jwtToken: null,
      };
    case UserActions.NEW_COMPANY_ADDRESS_START:
      return {
        ...state,
        loading: true,
      };
    case UserActions.NEW_COMPANY_ADDRESS_SUCCESS: {
      const payload = action.payload as any;
      return {
        ...state,
        addresses: [...state.addresses, payload.address],
        loading: false,
      };
    }
    case UserActions.NEW_COMPANY_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case UserActions.TOGGLE_NEW_NOTIFICATION:
      return {
        ...state,
        newNotification: !state.newNotification,
      };
    case UserActions.UPDATE_VENDOR_PROFILE_INFO_START:
      return {
        ...state,
        loading: true,
      };
    case UserActions.UPDATE_VENDOR_PROFILE_INFO_SUCCESS: {
      const payload = action.payload as UpdateVendorProfileResponse;
      const currentValue = { ...state };
      if (payload.poc_name) {
        currentValue.name = payload.poc_name;
      }
      if (payload.phone_number) {
        currentValue.phone_number = payload.phone_number;
      }
      if (payload.vendor_name && state.company_details) {
        currentValue.company_details = {
          ...state.company_details,
          name: payload.vendor_name,
          gst_number: payload.gst,
          capacity: payload.capacity,
        };
      }
      if (payload.vendor_images) {
        currentValue.vendor_images = payload.vendor_images;
      }
      if (payload.vendor_machines) {
        currentValue.vendor_machines = [
          ...currentValue.vendor_machines,
          ...payload.vendor_machines,
        ];
      }
      if (payload.vendor_products) {
        currentValue.vendor_products = [
          ...currentValue.vendor_products,
          ...payload.vendor_products,
        ];
      }
      if (payload.employee_count && currentValue.company_details) {
        currentValue.company_details.employee_count = payload.employee_count;
      }
      currentValue.loading = false;
      return currentValue;
    }
    case UserActions.UPDATE_VENDOR_PROFILE_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case UserActions.ADD_VENDOR_ADDRESS_START:
      return {
        ...state,
        loading: true,
      };
    case UserActions.ADD_VENDOR_ADDRESS_SUCCESS: {
      const payload = action.payload as UserAddress;
      return {
        ...state,
        addresses: [...state.addresses, payload],
        loading: false,
      };
    }
    case UserActions.ADD_VENDOR_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case UserActions.FETCH_VENDOR_DETAILS_START:
      return {
        ...state,
      };
    case UserActions.FETCH_VENDOR_DETAILS_SUCCESS: {
      const payload = action.payload as VendorDetails;
      return {
        ...state,
        ...payload,
      };
    }
    case UserActions.FETCH_VENDOR_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default userReducer;
