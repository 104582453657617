import React, { useEffect, useMemo, useState } from "react";
import "./Quote.scss";
import QuoteListCard from "../../components/QuoteListCard/QuoteListCard";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";
import { QuoteListSchema } from "../../models/Quote";
import { useLocation, useNavigate } from "react-router-dom";
import { debounce } from "lodash";

const Quote: React.FC = () => {
  const navigate = useNavigate();

  const [quoteList, setQuoteList] = useState<QuoteListSchema[]>([]);
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchedText, setSearchedText] = useState("");
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    getQuoteList(searchedText, currentPage).then((r) => {
      return r;
    });

    return () => {
      debouncedSearch.cancel();
    };
  }, []);

  const getQuoteList = async (searchText: string, pageNo: number) => {
    try {
      const { data } = await ApiService().client.get(URL.QUOTES.QUOTE_LIST, {
        params: {
          pageNumber: pageNo,
          search_text: searchText,
        },
      });
      const quoteListData = data.quotes;
      setTotalPages(data.total_pages);
      setQuoteList(quoteListData);
    } catch (e) {
      console.error(e);
    }
  };

  const debouncedSearch = useMemo(() => {
    return debounce((searchText: string, pageNo: number) => {
      setCurrentPage(currentPage);
      getQuoteList(searchText, pageNo).then((r) => {
        return r;
      });
    }, 500);
  }, [navigate]);

  const handleSearchFile = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const searchText = event.target.value;
    setSearchedText(searchText);
    debouncedSearch(searchText, 1);
  };

  const handleExpansion = (expandIndex: number) => {
    setExpandedIndex(expandIndex === expandedIndex ? -1 : expandIndex);
  };

  const handlePrevious = () => {
    if (currentPage - 1 === 0) {
      return;
    } else {
      getQuoteList(searchedText, currentPage - 1).then((r) => {
        return r;
      });
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage + 1 > totalPages) {
      return;
    } else {
      getQuoteList(searchedText, currentPage + 1).then((r) => {
        return r;
      });
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="quote-status-content-page full-width flex-column">
      <div className="quote-status-upper-bar px-32 py-12 xetgo-font-tag">
        <p>
          Digifac / <span className="sub-title bold">Quote List</span>
        </p>
      </div>
      <div className="quote-status-lower-main-container p-16 flex-column gap-12">
        <div
          className="quote-status-upper-search-filter-container position-relative flex-row justify-content-space-between
                      align-items-center gap-12 xetgo-font-tag"
        >
          <div className="search-bar-container">
            <input
              className="py-8 px-34 search-input border-box xetgo-font-tag"
              onChange={handleSearchFile}
              placeholder="Search ..."
            />
            <img
              height={16}
              width={16}
              className="search-icon "
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240515071345-search+%281%29.svg"
              alt="search-icon"
            />
          </div>
        </div>
        <div className="quote-status-lower-details-main-container">
          <div className="detail-heading-container xetgo-font-tag bold">
            <p className="index p-12"></p>
            <div className="flex-row gap-8 align-items-center p-12">
              <img
                height={12}
                width={12}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240515083543-table-properties.svg"
                alt="enquiry-icon"
              />
              <p>Enquiry ID</p>
            </div>
            <div className="enquiry-on flex-row gap-8 align-items-center p-12">
              <img
                height={12}
                width={12}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240516052108-calendar.svg"
                alt="enquiry-icon"
              />
              <p className="xetgo-font-tag">Enquiry on</p>
            </div>
            <div className="flex-row gap-8 align-items-center p-12">
              <img
                height={12}
                width={12}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240520063058-user.svg"
                alt="client-icon"
              />
              <p>Client</p>
            </div>{" "}
            <div className="flex-row gap-8 align-items-center p-12 quotation-value-container">
              <img
                height={12}
                width={12}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240520062831-rupee+1.svg"
                alt="rupee-icon"
              />
              <p>Quotation Value</p>
            </div>
            <div className="enquiry-on flex-row gap-8 align-items-center p-12">
              <img
                height={12}
                width={12}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240520063009-file-box+%282%29.svg"
                alt="product-icon"
              />
              <p>Products</p>
            </div>
            <div className="enquiry-on flex-row gap-8 align-items-center p-12">
              <img
                height={12}
                width={12}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240520063009-file-box+%282%29.svg"
                alt="status-icon"
              />
              <p>Status</p>
            </div>
          </div>
          <div className="quote-list-card-container flex-column quote-scrollable-container">
            {quoteList?.map((quote, index) => {
              return (
                <QuoteListCard
                  key={index}
                  index={index}
                  quoteListItem={quote}
                  expandedIndex={expandedIndex}
                  handleExpansion={handleExpansion}
                />
              );
            })}
            {quoteList.length === 0 && (
              <div className="empty-selection flex-column gap-24 align-items-center justify-content-center">
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240207115819-file-box+%281%29.svg"
                  alt="no-product"
                  width={36}
                  height={36}
                />
                <p className="empty-image-text xetgo-font-button bold">
                  Quote Not Found
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="xetgo-font-tag pagination-container flex-row align-items-center justify-content-space-between ">
          <div
            onClick={handlePrevious}
            className="paginate-button px-12 py-8 flex-row align-items-center gap-8 bold cursor-pointer"
          >
            <img
              height={16}
              width={16}
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231211081111-chevron-back.svg"
              alt="backward-icon"
            />
            Previous
          </div>
          <div className=" flex-row align-items-center">
            <p className="current-page py-8 px-12">{currentPage}</p>{" "}
            <span className="py-8 px-12 bold">of</span>{" "}
            <p className="bold py-8 px-12">{totalPages}</p>
          </div>
          <div
            onClick={handleNext}
            className="paginate-button flex-row align-items-center gap-8 px-12 py-8 bold cursor-pointer"
          >
            Next{" "}
            <img
              height={16}
              width={16}
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231211081111-chevron-forward.svg"
              alt="forward-icon"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quote;
