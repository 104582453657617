import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import "./CommentSection.scss";
import { getFileIcon } from "../../utils/Helper";
import { useParams } from "react-router-dom";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";
import { useSelector } from "react-redux";
import { userDetails } from "../../redux/user/userSelector";
import CommentCard from "./CommentCard/CommentCard";
import { QuoteCommentSchema } from "../../models/Quote";
import SocketService from "../../services/socketService";

interface CommentSectionProps {
  source: string;
  quote_id: number;
  handleClose: () => void;
  clientPocName: string;
  companyName: string;
}

const CommentSection: React.FC<CommentSectionProps> = ({
  source,
  quote_id,
  handleClose,
  clientPocName,
  companyName,
}) => {
  const { id } = useParams();
  const user = useSelector(userDetails);
  const [commentList, setCommentList] = useState<QuoteCommentSchema[]>([]);

  const [fileList, setFileList] = useState<File[]>([]);
  const [showIndex, setShowIndex] = useState(-1);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const descriptionRef = useRef<null | HTMLDivElement>(null);

  const getVendorComments = async () => {
    try {
      const { data } = await ApiService().client.get(
        URL.QUOTES.FETCH_VENDOR_COMMENTS(quote_id, user.company_id || 0)
      );
      setCommentList(data.comments);
    } catch (e) {
      console.error(e);
    }
  };

  const getPurchaseComments = async () => {
    try {
      const { data } = await ApiService().client.get(
        URL.PURCHASE.ADD_VENDOR_COMMENT(quote_id)
      );
      setCommentList(data.comments);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (source === "VENDOR") {
      getVendorComments();
    } else {
      getPurchaseComments();
    }
    const socket = SocketService.getInstance().getSocket();
    socket.on("COMMENT_NOTIFICATIONS", (data: any) => {
      setCommentList((rows) => [...rows, data.message]);
    });
  }, []);

  useEffect(() => {
    todayIndex();
    scrollToBottom();
  }, [commentList]);

  const scrollToBottom = () => {
    setTimeout(() => {
      if (messagesEndRef.current != null) {
        messagesEndRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }, 100);
  };

  const addComment = async () => {
    if (descriptionRef.current) {
      const formData = new FormData();
      formData.set("comment", descriptionRef.current?.innerHTML);
      if (fileList.length > 0) {
        fileList.forEach((file, index) => {
          formData.append("files[]", file);
          formData.append("displayNames[]", file.name);
        });
      }
      let url = URL.PURCHASE.ADD_VENDOR_COMMENT(quote_id);
      if (source === "QUOTE") {
        url = URL.QUOTES.ADD_VENDOR_COMMENT(quote_id, user.company_id || 0);
        formData.set("quote_id", quote_id.toString());
        formData.set("comment_type", "CLIENT_DISCUSSION");
      }
      descriptionRef.current.textContent = "";
      setFileList([]);
      try {
        const { data } = await ApiService().client.post(url, formData);
        const comment = data.comment;
        if (comment.avatar === null) {
          comment.avatar = "";
        }
        commentList.push(comment);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const todayIndex = () => {
    const today = dayjs().format("DD-MM-YY");
    let found = false;
    commentList.forEach((comment, index) => {
      if (comment.timestamp.startsWith(today) && !found) {
        setShowIndex(index);
        found = true;
      }
    });
  };

  const onEnterPress = (e: React.KeyboardEvent) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      addComment().then((r) => {
        return r;
      });
      if (descriptionRef.current) {
        descriptionRef.current.innerHTML = "";
      }
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const files = Array.from(event.target.files);
      setFileList(files);
      const displayNames = files.map((file) => file.name);
      const formData = new FormData();
      fileList.forEach((file, index) => {
        formData.append("files[]", file);
        const displayName = displayNames[index]?.trim();
        if (displayName) {
          formData.append("displayNames[]", displayName);
        }
      });
    }
  };

  const deleteFile = (file: File) => {
    setFileList((old) => old.filter((row) => row.name !== file.name));
  };

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="task-comments-container border-box flex-column justify-content-space-between p-12"
    >
      {true && (
        <div className="comment-header-box flex-row p-12 justify-content-space-between align-items-center">
          <div className="flex-row gap-12 align-items-center">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240516104354-chatbox.svg"
              width={16}
              height={16}
              alt="comment"
            />
            <p className=" header-title xetgo-font-tag bolder">
              {clientPocName},{" "}
              <span className="comment-head-sub-title">{companyName}</span>
            </p>
          </div>
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240511083320-x+%281%29.svg"
            width={16}
            height={16}
            className="cursor-pointer"
            onClick={handleClose}
            alt="cross"
          />
        </div>
      )}
      <div className="comment-list-container flex-column gap-12 p-16">
        {commentList.map((comment, index) => {
          return (
            <div key={index}>
              {showIndex === index && (
                <div className="today-border">
                  <p className="today xetgo-font-mini px-4">TODAY</p>
                </div>
              )}
              <CommentCard
                avatar={
                  comment.avatar ||
                  "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240516052108-user.svg"
                }
                name={comment.name}
                timestamp={comment.timestamp}
                description={comment.comment || comment.description}
                commentFiles={comment.files}
                user_id={comment.user_id}
              />
            </div>
          );
        })}
        <div ref={messagesEndRef} id="message-end-marker" />
      </div>
      <div className="add-comment-container full-width border-box">
        <div className="full-width p-8 border-box xetgo-font-tag comment-value">
          {fileList && (
            <div>
              {fileList.map((file: File, index: number) => {
                return (
                  <div
                    style={{ background: "white", height: "40px" }}
                    className="file-card flex-row p-8 justify-content-space-between border-box"
                    key={index}
                  >
                    <div className="file-left flex-row gap-16 align-items-center">
                      <img
                        src={getFileIcon(file)}
                        alt="file-icon"
                        height={24}
                        width={24}
                        className="file-preview"
                      />
                      <p className="xetgo-font-tag file-name">{file.name}</p>
                    </div>
                    <div className="flex-row gap-12 align-items-center">
                      <img
                        className="cursor-pointer"
                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231012084858-close.svg"
                        alt="close"
                        width={16}
                        height={16}
                        onClick={() => deleteFile(file)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div
            style={{ outline: "none" }}
            className="full-width p-8 border-box xetgo-font-tag "
            ref={descriptionRef}
            onKeyDown={onEnterPress}
            contentEditable={true}
          ></div>
        </div>

        <div className="attach-send-comment-container flex-row align-items-center justify-content-flex-end ">
          <input
            onChange={(event) => {
              handleFileChange(event).then((r) => {
                return r;
              });
            }}
            id="commentFileInput"
            name="file"
            type="File"
            style={{ display: "none" }}
            multiple
          />
          <label htmlFor="commentFileInput">
            <img
              className="cursor-pointer"
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240117075035-_WYSIWYG-toolbar-button.svg"
              alt="attachment-icon"
            />
          </label>

          <img
            onClick={addComment}
            className="cursor-pointer"
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240117075035-Send+Button.svg"
            alt="send-icon"
          />
        </div>
      </div>
    </div>
  );
};

export default CommentSection;
