import React, { useEffect, useRef, useState } from "react";
import "./SkuQualityCheck.scss";
import SkuQualityCheckCard from "../SkuQualityCheckCard/SkuQualityCheckCard";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import SkuQualityCheckTestCard from "../SkuQualityCheckTestCard/SkuQualityCheckTestCard";

interface SkuQualityCheckSchema {
  id: number;
  key: string;
  unit: string | null;
  value: string | null;
  sku_id: number;
}
interface SkuQualityCheckTestSchema {
  id: number;
  name: string;
  description: string;
  test_method: string | null;
}
interface SkuQualityCheckProps {
  addNewQuality: boolean;
  addNewQualityTest: boolean;
  skuId: number;
}
const SkuQualityCheck: React.FC<SkuQualityCheckProps> = ({
  addNewQuality,
  skuId,
  addNewQualityTest,
}) => {
  const [skuQualityChecks, setSkuQualityChecks] = useState<
    SkuQualityCheckSchema[]
  >([]);
  const [skuQualityCheckTests, setSkuQualityCheckTests] = useState<
    SkuQualityCheckTestSchema[]
  >([]);

  useEffect(() => {
    getSkuQualityChecks();
  }, []);

  const getSkuQualityChecks = async () => {
    try {
      const { data } = await ApiService().client.get(
        URLS.SKU.GET_SKU_QUALITY_CHECKS(skuId)
      );
      setSkuQualityChecks(data.sku_quality_checks);
      setSkuQualityCheckTests(data.sku_quality_tests);
    } catch (error) {
      console.error("Error Fetching Sku Quality Checks", error);
    }
  };

  return (
    <div className="sku-quality-check-main-container flex-column">
      <div className="quality-check-heading-container quality-check-grid xetgo-font-tag bold">
        <p className="empty-index p-12"></p>
        <div className="flex-row align-items-center">
          <div className="flex-row gap-8 align-items-center p-12 flex-1">
            <img
              height={12}
              width={12}
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240703143839-table-properties+%281%29.svg"
              alt="list-icon"
            />
            <p className="">Parameters</p>
          </div>
          <div className="flex-row gap-8 align-items-center p-12 unit-container">
            <img
              height={12}
              width={12}
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240731091657-book.svg"
              alt="unit-icon"
            />
            <p className="">Units</p>
          </div>
        </div>
        <div className="flex-row gap-8 align-items-center p-12">
          <img
            height={12}
            width={12}
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240731091736-book-open-check.svg"
            alt="unit-icon"
          />
          <p className="">Standard</p>
        </div>
      </div>

      <div className="flex-column gap-12">
        <div className="all-quality-check-container xetgo-font-tag ">
          {skuQualityChecks.map((quality: SkuQualityCheckSchema, index) => (
            <SkuQualityCheckCard
              key={quality.id}
              index={index}
              qualityCheck={quality}
            />
          ))}
          {skuQualityChecks.length == 0 && !addNewQuality && (
            <div className="empty-state-qc-card flex-column gap-12 align-items-center justify-content-center xetgo-font-tag bold">
              <img
                height={21}
                width={20}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240808061403-Document+add.svg"
                alt="add-file-icon"
              />
              <p>Add Parameters to reflect here</p>
            </div>
          )}
        </div>
        <div className="quality-test-main-container">
          <div className="quality-test-header quality-test-grid xetgo-font-tag bold">
            <p className="empty-index p-12"></p>
            <div className="flex-row gap-8 align-items-center p-12">
              <img
                height={12}
                width={12}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240703143839-table-properties+%281%29.svg"
                alt="list-icon"
              />
              <p className="">Quality Check Test</p>
            </div>
            <div className="flex-row gap-8 align-items-center p-12">
              <img
                height={12}
                width={12}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240731091736-book-open-check.svg"
                alt="unit-icon"
              />
              <p className="">Standard</p>
            </div>
          </div>
          <div className="all-quality-test">
            {skuQualityCheckTests.map((qct, index) => (
              <SkuQualityCheckTestCard
                key={qct.id}
                index={index}
                qcDetail={qct}
              />
            ))}
            {skuQualityCheckTests.length == 0 && !addNewQualityTest && (
              <div className="empty-state-qc-card flex-column gap-12 align-items-center justify-content-center xetgo-font-tag bold">
                <img
                  height={21}
                  width={20}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240808061403-Document+add.svg"
                  alt="add-file-icon"
                />
                <p>Add Quality Check Property to reflect here</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkuQualityCheck;
